import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../utils/common";
import { CommonButton } from "../utils/Button";
import * as color from "../utils/color";
import { UseCarCard } from "../components/UseCarCard";
import { useState, useEffect } from "react";
import { api, ReqAndRes } from "../api";
import { planName } from "../utils/common";
import { StripePlanURL } from "../setting";

export const Top = () => {
  const [lastPoint, setLastPoint] = useState<number>(0);
  const [userName, setUserName] = useState("");
  const [plan, setPlan] = useState("");
  const [userId, setUserId] = useState("");
  const [mail, setMail] = useState("");
  const [reservation, setReservation] = useState<
    ReqAndRes["GET usages/reservations/"]["res"]["data"]["results"]
  >([]);
  const [isStripeConnect, setIsStripeConnect] = useState(false);

  // let planRename = "";
  // if (plan === "platinum") {
  //   planRename = "プラチナム会員";
  // } else if (plan === "gold") {
  //   planRename = "ゴールド会員";
  // } else if (plan === "basic") {
  //   planRename = "ベーシック会員";
  // }

  // 現在のポイントなどを取得
  useEffect(() => {
    (async () => {
      await api("GET points/me/", null)
        .then((res) => {
          setLastPoint(res.data.lastPoint);
          setUserName(res.data.userName);
          setPlan(res.data.plan);
          setUserId(res.data.userId);
          setMail(res.data.mail);
          setIsStripeConnect(res.data.hasStripeConnect);
        })
        .catch((err) => {
          // 処理
        });
    })();
  }, []);
  // 予約履歴を取得
  useEffect(() => {
    (async () => {
      await getReservation();
    })();
  }, []);

  // 予約履歴の取得
  const getReservation = async () => {
    await api("GET usages/reservations/", { limit: 5 })
      .then((res) => {
        console.log(res.data);
        setReservation(res.data.results);
      })
      .catch((err) => {
        // 処理
      });
  };

  // 予約の削除
  const deleteReservation = async (rentalId: number) => {
    await api("DELETE usages/reservations/", { id: rentalId! })
      .then((res) => {
        // 処理
        console.log("削除完了・更新");
        (async () => {
          await getReservation();
        })();
      })
      .catch((err) => {
        // 処理
        console.log("削除失敗");
      });
  };

  // テスト用なので後で変えてもいいだろうなぁ。
  const StripePricingTable = () => {
    return (
      <stripe-pricing-table
        pricing-table-id={"prctbl_1MYAYJKLJhU8t7T3073zcAKv"}
        publishable-key={
          "pk_test_51MGx7TKLJhU8t7T37GYM59BQyhMwsbdMcffpL5c4h3dWxTa7TrCn53LnISxpWRuf2jih8OLrPQA2VBssBlpOVYFm00lfphS1d7"
        }
        client-reference-id={userId}
        customer-email={mail}
      />
    );
  };

  return (
    <>
      {!isStripeConnect && !!userId && mail? (
        <Container>
          <StripePricingTable />

          {/* <div>
            このアカウントは支払いの連携ができておりません。
            下記をクリックして支払いを完了させてから更新してください。
          </div>
          <div>
          {userId && mail && (
            <a
              href={
                "https://buy.stripe.com/test_7sI6oXebZ82EgNy288?client_reference_id="+
                userId+"&"
              }
            >
              <CommonButton>決済連携を行う</CommonButton>
            </a>
          )}
          </div> */}
        </Container>
      ) : (
        <Container>
          <Box>
            <Profile
              name={userName}
              rank={planName[plan!]!}
              remainingPoint={lastPoint}
            />
            <Link to="/rentals/new">
              <CommonButton marginBottom="20px;" marginTop="20px;">
                予約入力
              </CommonButton>
            </Link>
            <Link to="/rentals">
              <CommonButton marginBottom="20px;">利用履歴</CommonButton>
            </Link>
            <Link to="/register/confirm/">
              <CommonButton marginBottom="20px;">
                会員登録情報の確認と変更申請
              </CommonButton>
            </Link>
            <a href={StripePlanURL} target={"_blank"} rel="noreferrer"><CommonButton marginBottom="20px;">プランの変更</CommonButton></a>
            <Link to="">
              <CommonButton marginBottom="20px;">FAQ</CommonButton>
            </Link>
            <div>直近の予約</div>
            {!!reservation &&
              reservation.map((v, index) => {
                return (
                  <UseCarCard
                    key={index}
                    rentalId={v.id}
                    carName={v.car.name}
                    carPlace={v.car.parkingAddress}
                    useStartDate={v.startReservationDateTime}
                    useEndDate={v.endReservationDateTime}
                    prePoint={v.point.lastPoint + v.point.pointDiff}
                    consumedPoint={v.point.pointDiff}
                    postPoint={v.point.lastPoint}
                    rentalStatus={v.status}
                    onClickDelete={() => {
                      (async () => {
                        await deleteReservation(v.id);
                      })();
                    }}
                  />
                );
              })}
          </Box>
        </Container>
      )}
    </>
  );
};
const Profile = ({
  name,
  rank,
  remainingPoint,
}: {
  name?: string;
  rank?: string;
  remainingPoint?: number;
}) => {
  return (
    <>
      {rank}　{name}様　
      <br />
      今月の残り:{remainingPoint}pt
    </>
  );
};

const Box = styled.div`
  /* border: solid 1px; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

// const CommonButton = styled(_CommonButton)`
//   margin-top: 8px;
//   margin-bottom: 8px;
// `

import { useEffect, useState } from "react";
import { CommonButton } from "../../utils/Button";
import { api, ReqAndRes } from "../../api";
import { Link, Navigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

export const ExtendReservation = () => {
  // 処理
  let { reservationId } = useParams();
  // const [maxAvailableHour,setMaxAvailableHour] = useState()
  const [isAbleToExtend, setIsAbleToExtend] = useState<Boolean>(false);
  const [data, setData] =
    useState<ReqAndRes["GET usages/reservations/extend/"]["res"]["data"]>();
  const extendReservation = () => {
    (async () => {
        api("POST usages/reservations/extend/", { id: Number(reservationId) })
          .then((res) => {
            console.log("成功")
          })
          .catch((err) => {
            // エラーページにとばしたほうがいいかも。。
          });
      })();
  };

  const [isFinish,SetIsFinish]=useState(false)

  useEffect(() => {
    (async () => {
      api("GET usages/reservations/extend/", { id: Number(reservationId) })
        .then((res) => {
          setIsAbleToExtend(true);
          // 予約が取得できている状況。
          // setReservation(res.data)
          setData(res.data);
        })
        .catch((err) => {
          // エラーページにとばしたほうがいいかも。。
        });
    })();
  }, []);

  if(isFinish) {
    return <Navigate replace to="/"/>
  }

  return (
    <>
      <h2>延長予約申請画面</h2>
      {isAbleToExtend ? (
        <>
          <div>
            開始時間:
            {dayjs(data?.startReservationDateTime).format("YYYY-MM-DD HH:mm")}
          </div>
          <div>
            終了時間:
            {dayjs(data?.endReservationDateTime).format("YYYY-MM-DD HH:mm")}
          </div>
          <div>利用時間:２時間</div>
          <div>現在のポイント:{data?.lastPoint}</div>
          <div>消費ポイント:{data?.usePoint}</div>
          <div>
            利用後ポイント:{data?.lastPoint! - data?.usePoint!}
            (0以下の場合は予約できません。)
          </div>

          <CommonButton disabled={data?.lastPoint! - data?.usePoint! < 0} onClick={extendReservation}>
            確定
          </CommonButton>
          <Link to="/">
            <CommonButton>戻る</CommonButton>
          </Link>
        </>
      ) : (
        <>
          この車両は予約が入っているもしくは他の理由で延長予約できません。
          <Link to="/">
            <CommonButton>戻る</CommonButton>
          </Link>
        </>
      )}
    </>
  );
};

// const Title =

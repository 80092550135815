import { useState, useReducer, useEffect,useMemo } from "react";
import { CarLicenseCheck } from "../../components/CarLicenseCheck";
import { Container } from "../../utils/common";
import { CheckBeforeUsage } from "../../components/CheckBeforeUsage";
import { InputCarDamage } from "../../components/InputCarDamage";
import { DisplayKeyBox } from "./DisplayKeyBox";
import { CheckCarBodys } from "../../components/CheckCarBodys";
import { initialUseCarState, UseCarReducer } from "../../reducer/useCarReducer";
import { useParams } from "react-router-dom";
import { api, ReqAndRes } from "../../api";

export const UseCar = () => {
  const [mode, setMode] = useState(1);
  // 実験
  const [useCarState, useCarDispatch] = useReducer(
    UseCarReducer,
    initialUseCarState
  );
  // 免許証
  // 画像x8
  // 傷あるかどうか、
  // 車を選んだ段階で。
  let { reservationId } = useParams();
  const [reservation, setReservation] = useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]>();
  // const [car, setCar] = useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"]>();
  const [passCode,setPasscode] = useState("")
//   const getPassCode = async ()=>{
//     await api("GET usages/reservations/get_keyvox_pass_code/",{id:Number(reservationId!)}).then
//     ((res)=>{
//         console.log(res)
//         setPasscode(res.data.passCode)
//     }).catch(
//         (err)=>{console.log(err)}
//     )
// }
  const useCarAPI = ()=>{
    (async ()=>{
        let data:ReqAndRes["POST usages/"]["req"] = {
          car_images: {
            front_image: useCarState.frontImage?.value!,
            left_image: useCarState.leftImage?.value!,
            right_image: useCarState.rightImage?.value!,
            back_image: useCarState.backImage?.value!,
            front_left_image: useCarState.frontLeftImage?.value!,
            front_right_image: useCarState.frontRightImage?.value!,
            back_left_image: useCarState.backLeftImage?.value!,
            back_rightimage: useCarState.backRightImage?.value!,
          },
          damage_report: {
            text_content: useCarState.damageReportText?.value!,
            damege_image: useCarState.damageReportImage?.value!,
          },
          usage_type: "usage",
          license_image: useCarState.frontImage?.value!,
          car: reservation?.car.id!,
          reservation: reservation?.id!,
        }
        
        if(!(useCarState.damageReportText?.value!) && !useCarState.damageReportImage?.value!){
          data.damage_report = undefined
        }

        api("POST usages/",data).then(
          (res)=>{
            console.log("成功")
            // 成功したら次のページにいく
            setMode(5)
            
          }
        ).catch(
          (err)=>{
          }
        )
      }
    )()
  }


  useEffect(() => {
    (async () => {
      api("RETRIEVE usages/reservations/", {id:Number(reservationId)})
        .then((res) => {
          // 予約が取得できている状況。
          setReservation(res.data)
        })
        .catch((err) => {
          // エラーページにとばしたほうがいいかも。。
        })
    })();

    // TODO 穴ではある。どこかで書き直したいところ。
    (async ()=>{
      await api("GET usages/reservations/get_keyvox_pass_code/",{id:Number(reservationId!)}).then
      ((res)=>{
          console.log(res)
          setPasscode(res.data.passCode)
      }).catch(
          (err)=>{console.log(err)}
      )
    })();

  }, [reservationId]);

  const CarLicenseMemo = useMemo(
    ()=>{return <CarLicenseCheck
      onClick={() => {
        setMode(2);
      }}
      state={useCarState}
      dispatch={useCarDispatch!}
    />}
    ,[])


  // let Content = () => {
  //   return <></>;
  // };
  if (mode === -1) {
    return <Container>
        お支払いを完了いないため利用ができません。
      </Container>
  } 

  if (mode === 1) {
      return <Container>
          {CarLicenseMemo}
        </Container>
    }    
    if (mode === 2) {
      return <Container>
          <CheckBeforeUsage
            onClick={() => {
              setMode(3);
            }}
          />
        </Container>
    } else if (mode === 3) {
      return <Container>
          <CheckCarBodys
            onClickNext={() => {
              setMode(4);
            }}
            car={reservation!["car"]}
            useCarState={useCarState}
            useCardispatch={useCarDispatch!}
          />
        </Container>
    } else if (mode === 4) {
      return <Container>
          <InputCarDamage
            onClickNext={() => {
              setMode(5);
            }}
            useCarState={useCarState}
            useCardispatch={useCarDispatch!}
            useCarAPI={useCarAPI}
          />
        </Container>
    } else if (mode === 5) {
      return <Container>
          <DisplayKeyBox isPreUse={true} passCode={passCode} keyvoxPlace={reservation!["car"]["keyBoxPlace"]}/>
        </Container>
    };

  return <></>;
};

import { CommonButton as _CommonButton,AgreeButton as _AgreeButton } from "../../utils/Button";
import { Container } from "../../utils/common";
import styled from "styled-components";
import { useState,useReducer, useEffect,useMemo } from "react";
import { NomalReturn } from "./NomalReturn";
import { CheckCarBodys } from "../../components/CheckCarBodys";
import * as color from "../../utils/color";
import { InputCarDamage } from "../../components/InputCarDamage";
import { DisplayKeyBox } from "../UseCar/DisplayKeyBox";
import { initialUseCarState, UseCarReducer } from "../../reducer/useCarReducer";
import { Navigate, useParams } from "react-router-dom";
import { api, ReqAndRes } from "../../api";

export const ReturnCar = () => {


  const [passCode,setPasscode] = useState("")


  const [mode, setMode] = useState(1);
  const [useCarState, useCarDispatch] = useReducer(
    UseCarReducer,
    initialUseCarState
  );
  // 免許証
  // 画像x8
  // 傷あるかどうか、
  // 車を選んだ段階で。
  let { reservationId } = useParams();
  const [reservation, setReservation] = useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]>();
  // const [car, setCar] = useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"]>();
  const useCarAPI = ()=>{
    (async ()=>{
        let data:ReqAndRes["POST usages/"]["req"] = {
          car_images: {
            front_image: useCarState.frontImage?.value!,
            left_image: useCarState.leftImage?.value!,
            right_image: useCarState.rightImage?.value!,
            back_image: useCarState.backImage?.value!,
            front_left_image: useCarState.frontLeftImage?.value!,
            front_right_image: useCarState.frontRightImage?.value!,
            back_left_image: useCarState.backLeftImage?.value!,
            back_rightimage: useCarState.backRightImage?.value!,
          },
          damage_report: {
            text_content: useCarState.damageReportText?.value!,
            damege_image: useCarState.damageReportImage?.value!,
          },
          usage_type: "return",
          license_image: useCarState.frontImage?.value!,
          car: reservation?.car.id!,
          reservation: reservation?.id!,
        }

        if(!(useCarState.damageReportText?.value!) && !useCarState.damageReportImage?.value!){
          data.damage_report = undefined
        }

        api("POST usages/",data).then(
          (res)=>{
            console.log("成功")
            // 成功したら次のページにいく
            // TODOここを書き換える
            setMode(9)
          }
        ).catch(
          (err)=>{

          }
        )
      }
    )()
  }


  useEffect(() => {
    (async () => {
      api("RETRIEVE usages/reservations/", {id:Number(reservationId)})
        .then((res) => {
          // 予約が取得できている状況。
          setReservation(res.data)
        })
        .catch((err) => {
          // エラーページにとばしたほうがいいかも。。
        })
    })();
    // TODO 穴ではある。どこかで書き直したいところ。
    (async ()=>{
      await api("GET usages/reservations/get_keyvox_pass_code/",{id:Number(reservationId!)}).then
      ((res)=>{
          console.log(res)
          setPasscode(res.data.passCode)
      }).catch(
          (err)=>{console.log(err)}
      )
    })();
  }, [reservationId]);


  if (mode === 1) {
    // 予定通り返却した場合
    return (
      <Container>
        <NomalReturn
          car={reservation?.car!}
          reservationId={Number(reservationId)}
          nextOnClick={() => {
            console.log("tes")
            setMode(7);
          }}
        />
      </Container>
    );
  }

  if (mode === 7) {
    // 予定通り返却した場合の次の画面
    const postImageAndNext = ()=>{
      setMode(8)
    }
    return (
      <Container>
        {/* 通常返却した場合 */}
        <CheckCarBodys onClickNext={postImageAndNext}
        car={reservation!["car"]}
        useCarState={useCarState}
        useCardispatch={useCarDispatch!}
        />
      </Container>
    );
  }

  if (mode === 8) {
    // 外観チェックした後
    // const postAndNext = ()=>{
    //   setMode(9)
    // }
    return (
      <Container>
        {/* 通常返却した場合 */}
        <InputCarDamage 
        onClickNext={() => {
          setMode(9);
        }}
        useCarState={useCarState}
        useCardispatch={useCarDispatch!}
        useCarAPI={useCarAPI}
        />
      </Container>
    );
  }

  if (mode === 9) {
    // 外観チェックした後
    return (
      <Container>
        {/* 通常返却した場合 */}
        {/* <Key onClickNext={()=>{}}/> */}
        <DisplayKeyBox passCode={passCode} keyvoxPlace={reservation!["car"]["keyBoxPlace"]}/>
      </Container>
    );
  }

  return <></>;
};

const  AgreeButton = styled(_AgreeButton)`
  margin-bottom: 10px; ;
`;

// ここにAPIを書いていく。
// loginからかく？
// トークンの確認もいる。
import axios from "axios";
import { BaseURL } from "./setting";

type loginType = {
  "POST auth/resident-login/": {
    req: {
      email: string;
      password: string;
    };
    res: {
      data: {
        accessToken: string;
        refreshToken: string;
      };
    };
  };
  "POST users/examination_users/": {
    req: {
      lastName: string;
      firstName: string;
      lastNameKana: string;
      firstNameKana: string;
      dateOfBirth: string;
      prefecture: string;
      address1: string;
      address2: string;
      address3?: string;
      phoneNumber: string;
      hasAccidentHistory: number;
      hasPenaltyHistory: number;
      licenseImage: string;
      licenseValidDate: string;
      // plan: string;
      mail: string;
      password: string;
      owner: string;
    };
    res: {};
  };

  "GET users/residence_users/me/": {
    req: null;
    res: {
      data: {
        id: string;
        username: string;
        firstName: string;
        lastName: string;
        dateJoined: string;
        email: string;
        userType: string;
        createdAt: string;
        updatedAt: string;
        lastNameKana: string;
        firstNameKana: string;
        dateOfBirth: string;
        prefecture: string;
        address1: string;
        address2: string;
        phoneNumber: string;
        hasAccidentHistory: boolean;
        hasPenaltyHistory: boolean;
        licenseImage: string;
        licenseValidDate: string;
        plan: string;
        mail: string;
        hasStripeConnect: boolean;
        owner: string;
      };
    };
  };

  "PATCH users/residence_users/": {
    req: {
        id: string;
        firstName?: string;
        lastName?: string;
        dateJoined?: string;
        email?: string;
        lastNameKana?: string;
        firstNameKana?: string;
        dateOfBirth?: string;
        prefecture?: string;
        address1?: string;
        address2?: string;
        phoneNumber?: string;
        hasAccidentHistory?: boolean;
        hasPenaltyHistory?: boolean;
        licenseImage?: string;
        licenseValidDate?: string;
    };
    res: {
      data: {
        id: string;
        username: string;
        firstName: string;
        lastName: string;
        dateJoined: string;
        email: string;
        userType: string;
        createdAt: string;
        updatedAt: string;
        lastNameKana: string;
        firstNameKana: string;
        dateOfBirth: string;
        prefecture: string;
        address1: string;
        address2: string;
        phoneNumber: string;
        hasAccidentHistory: boolean;
        hasPenaltyHistory: boolean;
        licenseImage: string;
        licenseValidDate: string;
        plan: string;
        mail: string;
        hasStripeConnect: boolean;
        owner: string;
      };
    };
  };
};

type S3Type = {
  "GET s3/get-presigned-url/": {
    req: null;
    res: {
      data: {
        url: string;
        path: string;
      };
    };
  };
};

type CarType = {
  "GET cars/for_residence_user/": {
    req: null;
    res: {
      data: {
        count: number;
        next: string;
        before: string;
        results: {
          id: number;
          isAvailable?: number;
          createdAt?: string;
          updatedAt?: number;
          name?: string;
          grade?: string;
          parkingAddress?: string;
          specipication?: string;
          size?: string;
          carNo?: string;
          carInspectionCertificate?: string;
          needPoint?: number;
          isDraft?: true;
          registerDateOfCarInspection?: string;
          numberPlate?: string;
          ownerTel?: string;
          insuranceLicenseNo?: string;
          keyBoxPlace?: string;
          keyBoxNo?: string;
          insuranceCompanyName?: string;
          insuranceCompanyContactName?: string;
          insuranceCompanyTel?: string;
          frontImage?: string;
          leftImage?: string;
          rightImage?: string;
          backImage?: string;
          frontLeftImage?: string;
          frontRightImage?: string;
          backLeftImage?: string;
          backRightImage?: string;
          thumbnailImage?: string;
          isDelete?: false;
          owner?: string;
        }[];
      };
    };
  };
  "GET cars/schedule/": {
    req: {
      id: number;
    };
    res: {
      data: { start: string; end: string; type?: string }[];
    };
  };
};

type PointType = {
  "GET points/me/": {
    req: null;
    res: {
      data: {
        lastPoint: number;
        userName: string;
        plan: string;
        hasStripeConnect:boolean;
        userId: string,
        mail:string,
      };
    };
  };
};

type UsageType = {
  "POST usages/bad-news/":{
    req:{
      type: string,
      reservation: number,
      accidentAt?: string,
      contentDetail?: string,
      placeDetail?: string,
      isOwnerCheck?: boolean,
      isReturn?: boolean,
      managerName?:string,
      managerPhoneNumber?:string
    }
    res:{
      data:null
    }
  }
  "POST usages/reservations/": {
    req: {
      startReservationDateTime: string;
      endReservationDateTime: string;
      useTime: number;
      car: number;
      isPenalty?: boolean;
    };
    res: {
      id: number;
      startReservationDateTime: string;
      endReservationDateTime: string;
      useTime: number;
      car: number;
      isPenalty: boolean;
    };
  };
  "GET usages/reservations/": {
    req: { limit?: number } | null;
    res: {
      data: {
        results: {
          id: number;
          createdAt: string;
          updatedAt: string;
          startReservationDateTime: string;
          endReservationDateTime: string;
          useTime: number;
          status: number;
          user: string;
          point: {
            lastPoint: number;
            pointDiff: number;
            type: string;
          };
          car: {
            id: number;
            name: string;
            parkingAddress: string;
            keyBoxPlace: string;
          };
        }[];
      };
    };
  };

  "GET usages/reservations/extend/": {
    req: { id: number };
    res: {
      data: {
        maxAvailableHour: number;
        startReservationDateTime: string;
        endReservationDateTime: string;
        usePoint: number;
        lastPoint: number;
      };
    };
  };

  "POST usages/reservations/extend/": {
    req: { id: number };
    res: {
      data: string;
    };
  };

  "GET usages/reservations/extend-penalty/": {
    req: { id: number; useTime: number };
    res: {
      data: {
        maxAvailableHour: number;
        startReservationDateTime: string;
        endReservationDateTime: string;
        usePoint: number;
        lastPoint: number;
      };
    };
  };

  "POST usages/reservations/extend-penalty/": {
    req: { id: number; useTime: number };
    res: {
      data: string;
    };
  };

  "RETRIEVE usages/reservations/": {
    req: { id: number };
    res: {
      data: {
        id: number;
        car: {
          id: number;
          isAvailable: number;
          createdAt: string;
          updatedAt: string;
          name: string;
          grade: string;
          parkingAddress: string;
          specipication: string;
          size: string;
          carNo: string;
          carInspectionCertificate: string;
          needPoint: number;
          isDraft: boolean;
          registerDateOfCarInspection: string;
          numberPlate: string;
          ownerTel: string;
          insuranceLicenseNo: string;
          keyBoxPlace: string;
          keyBoxNo: string;
          insuranceCompanyName: string;
          insuranceCompanyContactName: string;
          insuranceCompanyTel: string;
          frontImage: string;
          leftImage: string;
          rightImage: string;
          backImage: string;
          frontLeftImage: string;
          frontRightImage: string;
          backLeftImage: string;
          backRightImage: string;
          thumbnailImage: string;
          isDelete: boolean;
          owner: string;
        };
        createdAt: string;
        updatedAt: string;
        startReservationDateTime: string;
        endReservationDateTime: string;
        useTime: number;
        status: number;
        user: string;
        point: number;
      };
    };
  };

  "DELETE usages/reservations/": {
    req: { id: number };
    res: null;
  };
  "POST usages/": {
    req: {
      car_images: {
        front_image: string;
        left_image: string;
        right_image: string;
        back_image: string;
        front_left_image: string;
        front_right_image: string;
        back_left_image: string;
        back_rightimage: string;
      };
      damage_report?: {
        text_content: string;
        damege_image: string;
      };
      usage_type: string;
      license_image: string;
      car: number;
      reservation: number;
    };
    res: null;
  };

  "GET usages/reservations/get_keyvox_pass_code/": {
    req: { id: number };
    res: {
      data: {
        passCode: string;
      };
    };
  };
};

export type ReqAndRes = loginType & S3Type & CarType & PointType & UsageType;

export function api<K extends keyof ReqAndRes>(
  key: K,
  payload: ReqAndRes[K]["req"]
): Promise<ReqAndRes[K]["res"]> {
  // なにもない場合はnullっぽい
  const accessToken = localStorage.getItem("accessToken");
  const headers = {
    Authorization: `jwt ${accessToken}`,
  };
  const config = {
    headers: headers,
  };

  // console.log(`ローカルストレージの実験${test}`)
  // どこからかとってくる必要がある
  // 　headers={}
  // const queryDetail = useSelector((state) => state.queryDetail, shallowEqual);
  const [method, path] = key.split(" ");
  // メソッドもパスもない場合。
  if (!method || !path) {
    throw new Error(`Unrecognized api: ${key}`);
  }
  const option: RequestInit = { method };
  const url = BaseURL + path;
  // let header;
  let pathWithID = "";

  //   ここらへんは後で書く。
  switch (option.method) {
    case "GET":
      if (path === "s3/get-presigned-url/") {
        return axios.get(BaseURL + path, { params: payload });
      }
      break;

    // 後ほどコメントアウト外す。
    case "DELETE":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      option.headers = { "Content-Type": "application/json" };
      return axios.delete(BaseURL + pathWithID, config);

    case "RETRIEVE":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      return axios.get(BaseURL + pathWithID, config);

    case "POST":
      // 登録周りだからauthしてはいけない。ここに書くべきではないと思う。
      if (
        path === "auth/resident-login/" ||
        path === "auth/register/verify-email/" ||
        path === "users/register/owner/"||
        path === "users/examination_users/"
      ) {
        return axios.post(BaseURL + path, payload);
      }
      // option.body = JSON.stringify(payload);
      return axios.post(BaseURL + path, payload, config);

    case "PATCH":
      if (payload && "id" in payload) {
        pathWithID = `${path}${payload.id}/`;
      }
      //   option.body = JSON.stringify(payload);
      //パッチの場合
      return axios.patch(BaseURL + pathWithID, payload, config);
  }

  return axios.get(url, {
    headers: headers,
    params: payload,
  });

  //   getパラメータをpayloadで制御できるようにした。
}
import { useState } from "react";
import { CommonButton } from "../../utils/Button";
// import Checkbox from "@mui/material/Checkbox";
import { Row, RowContent, RowTitle } from "../../utils/common";
import { TakePhoto } from "../../components/TakePhoto";
import CarLicenseImage from "../../materials/license_sample.jpg";
import { CommonTextField } from "../../utils/TextField";
import { SingleCheckBox } from "../../utils/CheckBox";
import { CameraImage } from "../../utils/common";
import { AttentionWithRed } from "../../utils/common";
import * as React from "react";

type Props = {
  dispatch?: any;
  state?:any
};

export const CarLicense =  React.memo(({ dispatch,state }: Props) => {
  // const [check, setCheck] = useState(false);
  const [cameraMode, setCameraMode] = useState(false);
  // const [image, setImage] = useState();
  const handleCheck = () => {
    dispatch({ type: "ACTION_INPUT_CAR_LICENSE_ADDRESS_CHECK" });
  };

  return (
    <>
      <Row>
        <RowTitle>免許証の確認</RowTitle>
        <RowTitle>以下のように撮影してください</RowTitle>
        <RowContent>
          {/* 枠を出すみたいなのは宿題としておいておく */}

          <img
            src={CarLicenseImage}
            style={{ width: "100%", height: "100%" }}
            alt="免許証サンプル"
          />
        </RowContent>
      </Row>
      <Row>
        <RowContent>
          <CommonButton
            onClick={() => {
              setCameraMode(true);
            }}
          >
            撮影(再撮影)
          </CommonButton>
        </RowContent>
        <RowContent>
          {cameraMode && (
            <TakePhoto
              image={state.carLicenseImage.value!}
              setImage={(v) => {
                dispatch({ type: "ACTION_INPUT_CAR_LICENSE_IMAGE", payload: {inputValue:v} });
                // setImage(v);
              }}
              setImageS3path={(v)=>{
                dispatch({ type: "ACTION_INPUT_CAR_LICENSE_IMAGE_S3_PATH", payload: {inputValue:v} });
              }}
              setCameraMode={setCameraMode}
            />
          )}
          {state.carLicenseImage.value! && <CameraImage src={state.carLicenseImage.value} alt="撮影した免許証" />}
        </RowContent>
      </Row>

      <Row>
        <RowTitle>免許証記載住所</RowTitle>
        <RowContent>
          <SingleCheckBox
            label={"現在の居住地と一致する"}
            value={state.licenseAddressCheck.value}
            onChange={handleCheck}
          />
        </RowContent>
      </Row>

      <Row>
        <RowTitle>免許証有効期限</RowTitle>

        <RowTitle>年(西暦)</RowTitle>
        {state.licenseYear.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.licenseYear.value}
            onChange={(e) => {
              dispatch({ type: "ACTION_INPUT_CAR_LICENSE_YEAR", payload: {inputValue:e.target.value} });
            }}
            // style={{ width: "60px" }}
          />
        </RowContent>
        <RowTitle>月</RowTitle>
        {state.licenseMonth.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.licenseMonth.value}
            onChange={(e) => {
              dispatch({
                type: "ACTION_INPUT_CAR_LICENSE_MONTH",
                 payload: {inputValue:e.target.value} 
              });
            }}
            // style={{ width: "20px" }}
          />
        </RowContent>

        <RowTitle>日</RowTitle>
        {state.licenseDay.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.licenseDay.value}
            onChange={(e) => {
              dispatch({ type: "ACTION_INPUT_CAR_LICENSE_DAY", payload: {inputValue:e.target.value} });
            }}
            // style={{ width: "20px" }}
          />
        </RowContent>
      </Row>
    </>
  );
});

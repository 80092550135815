import styled from "styled-components";
import * as color from "./color";
import { ReactNode } from "react";

export const Row = styled.div`
  display: flex;
  width: 100%;
  /* スマホなので縦のほうがよさそう */
  flex-direction: column;
  margin-bottom: 20px;
  justify-content: left;
  text-align: left;
  align-items: center;
`;

export const RowTitle = styled.div`
  /* min-width: 80px; */
  /* test用 */
  display: flex;
  justify-content: center;
  width:100%;
  /* margin-right: 8px; */
`;
export const RowContent = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  /* test用 */
  /* border: solid 1px; */
`;

export const Container = ({ children }: { children: ReactNode }) => {
  return (
    <ContainerWrapper>
      <ContainerWraped>{children}</ContainerWraped>
    </ContainerWrapper>
  );
};

export const ContainerWraped = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  margin-bottom:30px; 
`;

const ContainerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom:100px;  */
`;

export const CameraImageWrapper = styled.div`
`

export const CameraImage= styled.img`
transform: rotate(90deg);
margin-top:-50px ;
margin-bottom:-50px ;
aspect-ratio: 9 / 16;
width:180px;
/* height:300px; */
`

export const AttentionWithRed = styled.div`
color:${color.Red}
`

export const planName:{[key:string]:string} = {
    "platinum":"プラチナム会員",
    "gold":"ゴールド会員",
    "basic":"ベーシック会員"
  }
import styled from "styled-components";
import * as color from "../utils/color";
import { useState } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { CancelButton} from "../utils/Button";
import {Link} from "react-router-dom";
import { useDispatch } from "react-redux";
import { StripePlanURL } from "../setting";
export function Header() {
    const [isOpenMenu,setIsOpenMenu]= useState(false)
    const dispatch = useDispatch()
    const logout = ()=>{
      dispatch({type:"Login.SetLogout"})
      // アクセストークンの保存
      localStorage.removeItem("accessToken")
      // リフレッシュトークンの保存
      localStorage.removeItem("refreshToken")
      // トークン取得日の保存。
      localStorage.removeItem("tokenDate")  
    }

    return (
      <>
      <Container>
        <Menu>
        <IconButton onClick={()=>{setIsOpenMenu(true)}}>
            <MenuIcon/>
        </IconButton>
        </Menu>
        <Title>カーシェアリングサービス</Title>
      </Container>
      
      {isOpenMenu && 
      <OverRay onClick={()=>{setIsOpenMenu(false)}}>
      <MenuContent onClick={()=>{}}>
        <Link to="/"><MenuItem>トップページ</MenuItem></Link>
        <Link to="/rentals/new"><MenuItem>新規予約</MenuItem></Link>
        <Link to="/rentals"><MenuItem>利用履歴</MenuItem></Link>
        {/* <Link to="/use"><MenuItem>貸出</MenuItem></Link>
        <Link to="/return"><MenuItem>返却</MenuItem></Link> */}
        <Link to="/register/confirm/"><MenuItem>登録情報の確認</MenuItem></Link>
        <a href={StripePlanURL} target={"_blank"} rel="noreferrer"><MenuItem>プランの変更</MenuItem></a>
        <MenuItem onClick={logout}>ログアウト</MenuItem>
        <CancelButton marginTop="10px;" onClick={()=>setIsOpenMenu(false)}>×閉じる</CancelButton>
        </MenuContent>
        </OverRay>
        }
      </>
    );
  }


  const MenuItem = styled.div`
  border-bottom:1px solid ${color.Gray2};
  padding:10px;
  font-weight:700;
  cursor: pointer;
  `
  const OverRay =styled.div`
   position:absolute;
   width:100%;
   height:100%;
  `

  const MenuContent = styled.div`
  position:absolute;
  font-size:18px;
  width:200px ;
/* border:solid 1px; */
height:100%;
background-color:${color.White} ;
border:solid 2px ${color.Gray2};
  `;
  
  
  const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    margin-top:0;
    margin-bottom:10px ;
  `;
const Menu = styled.div`

`;

const Title = styled.div`
display: flex;
font-size: 20px;
font-weight:700;

`;
  

import styled from "styled-components";
import * as color from "../utils/color";
import { useState } from "react";
import { CommonButton } from "../utils/Button";
import {
  Link,
} from "react-router-dom";
import { CommonTextField } from "../utils/TextField";
import LogoIcon from "../sozai/logo.jpg"
import { api } from "../api";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";

export function Login() {

  const [mailAddress, setMailAddress] = useState("");
  const [password, setPassword] = useState("");
  const disptch = useDispatch()
  const [errorMessage,setErrorMessage] = useState("")


  // const [loading, setLoading] = useState(false);
  // ログイン関数
  const login = ()=>{
    (async ()=>{
      await api("POST auth/resident-login/",{email:mailAddress,password:password}).then(
        (res)=>{
          // アクセストークンの保存
          localStorage.setItem("accessToken",res.data.accessToken)
          // リフレッシュトークンの保存
          localStorage.setItem("refreshToken",res.data.refreshToken)
          // トークン取得日の保存。
          localStorage.setItem("tokenDate",dayjs().format())  
          // 問題ないので、ログイン状態に変える。
          disptch({type:"Login.SetLogin"})
        }
      ).catch(
      (e)=>{
        setErrorMessage("メールアドレスかパスワードが間違っています。")
        console.log(e)
      }
      )
      
    })()
  }

  // ローカルストレージに保存する。
  //
  // const login = () => {
    // accessTokenとrefreshTokenをクッキー？localstrage?
    // localStorage.setItem('myCat', 'Tom');
    // ローカルストレージ key,
  //   // ログイン処理
  //   (async () => {})();
  // };

  return (
    <Container>
      <LogoWrapper>
      <Logo src={LogoIcon} alt=""/>
      <LogoTitle>フォレスト札幌南
      <LogoTitleSub>カーシェアリングサービス</LogoTitleSub>
      </LogoTitle>
      </LogoWrapper>
      <LoginBox>
        <MailFeild
          id="mail-address"
          value={mailAddress}
          placeholder="メールアドレス"
          onChange={(e)=>{setMailAddress(e.target.value)}}
        />

        <PasswordFeild
          id="password"
          value={password}
          type="password"
          placeholder="パスワード"
          onChange={(e)=>{setPassword(e.target.value)}}
          marginTop="10px"

        />
        {/* <Link to="/register"><CommonButton marginTop="10px">新規登録</CommonButton></Link> */}
        <ErrorMessage>{errorMessage}</ErrorMessage>
        <Link to="/"><CommonButton marginTop="10px" onClick={login}>ログイン</CommonButton></Link>
        <div><DecoratedLink href="https://storage.forest-sapporo-car-sharing.com/car-share.pdf">サービスについて</DecoratedLink></div>
      </LoginBox>
    </Container>
  );
}

const DecoratedLink = styled.a`
  text-decoration: none;
  color: ${color.Blue2};
`

const ErrorMessage = styled.div`
color:${color.Red};
`




const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height:100vh;
  /* background-color:${color.Blue1} ; */
`;

//   ここにはロゴが入る。確認用のため
const LogoWrapper = styled.div`
margin:50px;
display:flex;
justify-content:center ;
align-items:center ;
flex-direction:column ;
`

const LogoTitle = styled.div`
font-weight:1500;
color:${color.Blue2};
font-size:32px;
text-align:center ;
`
const LogoTitleSub = styled.div`
/* font-weight:500; */
color:${color.Blue2};
font-size:18px;
text-align:center ;
`

const Logo = styled.img`
  /* width: 80%; */
  width:150px;
  /* height: 230px; */
  /* margin:50px; */
  /* background-color: ${color.Gray1}; */
  /* border: solid 1px ${color.Gray1}; */
`;

const LoginBox = styled.div`
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  margin: 10px;
  padding: 0;
  width: 80%;
`;

const MailFeild = styled(CommonTextField)`
margin-bottom: 4px;
`

const PasswordFeild = styled(CommonTextField)`
`
import styled from "styled-components";
import { Dispatch, useState} from "react";
import { CommonButton } from "../utils/Button";
import { TakePhoto } from "./TakePhoto";
import * as React from "react";
import CarLicenseImage from "../materials/license_sample.jpg";
import { UseCarAction,UseCarActionType,UseCarStateType } from "../reducer/useCarReducer";

//型をもうちょいちゃんと書いてもいいかも。
export const CarLicenseCheck = React.memo(({ onClick,dispatch,state }:{onClick?:()=>void,dispatch?:any,state?:any} ) => {
  const [image, setImage] = useState();
  const [cameraMode, setCameraMode] = useState(false);
  console.log("カメラチェックレンダリング")

  return (
    <Container>
      <div>免許証の確認</div>
      以下のように撮影してください
      <br />
      <img
        src={CarLicenseImage}
        style={{ width: "100%", height: "100%" }}
        alt="免許証サンプル"
      />
      <CommonButton
        onClick={() => {
          setCameraMode(true);
        }}
      >
        撮影(再撮影)
      </CommonButton>

      {cameraMode && (
        <TakePhoto
          setImage={setImage}
          // image={image}
          setCameraMode={setCameraMode}
          setImageS3path={(v)=>{
            dispatch?.({ type: UseCarActionType.ACTION_INPUT_CAR_LICENSE_IMAGE, payload: {inputValue:v} });
          }}
        />
      )}
      {image && <MyImage src={image}/>}
      <CommonButton onClick={()=>{onClick!()}} disabled={!image}>次へ</CommonButton>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const MyImage = styled.img`
/* transform: rotate( 90deg ); */
width:100%
`

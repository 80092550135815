import { Link } from "react-router-dom";
import styled from "styled-components";
import { Container } from "../utils/common";
import { CommonButton,CancelButton,AgreeButton } from "../utils/Button";
import * as color from "../utils/color";
import { api } from "../api";
import dayjs from "dayjs";

import isBetween from 'dayjs/plugin/isBetween';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);


export const UseCarCard = ({
    rentalId,
    carName,
    carPlace,
    useStartDate,
    useEndDate,
    prePoint,
    consumedPoint,
    postPoint,
    rentalStatus,
    onClickDelete
  }: {
    rentalId?: number;
    carName?: string;
    carPlace?: string;
    useStartDate?: string;
    useEndDate?: string;
    prePoint?: number;
    consumedPoint?: number;
    postPoint?: number;
    rentalStatus?:number;
    onClickDelete?:()=>void
  }) => {
    let statusJapanese = ""
    if(rentalStatus===0){
      statusJapanese = "予約中"
    }
    else if(rentalStatus===1){
      statusJapanese = "貸出中"
    }
    else if(rentalStatus===2){
      statusJapanese = "返却済み(延長予約も含む)"
    }
    else if(rentalStatus===-1){
      statusJapanese = "車体トラブルによりキャンセルされました"
    }

    const now = dayjs()
    // statusが０で、利用開始より今が後で、　今より利用終了が後　なら利用可能
    const isUseOk = rentalStatus===0 && now.isAfter(dayjs(useStartDate)) && dayjs(useEndDate).isAfter(now)
    

    return (
      <>
        <PredictBox>
          <div>車体:{carName}</div>
          <div>場所:{carPlace}</div>
          <div>ステータス:{statusJapanese}</div>
          <div>利用開始予定日:{dayjs(useStartDate).format("YYYY-MM-DD HH:mm")}</div>
          <div>利用終了予定日:{dayjs(useEndDate).format("YYYY-MM-DD HH:mm")}</div>
          <div>利用前ポイント:{prePoint}</div>
          <div>消費ポイント:{consumedPoint}</div>
          <div>利用後ポイント:{postPoint}</div>
          <ButtonGroup>
          <Link to={"/use/"+rentalId!+"/"}><AgreeButton padding="4px" width="80px"  marginRight="4px" marginBottom="4px" disabled={!isUseOk }>貸出</AgreeButton></Link>
          <Link to={"/return/"+rentalId!+"/"}><AgreeButton padding="4px"  width="80px"  marginRight="4px" marginBottom="4px" disabled={!(rentalStatus===1)}>返却</AgreeButton></Link>
          <Link to={"/rentals/extend/"+rentalId}><CommonButton  padding="4px" width="120px"  marginRight="4px" marginBottom="4px" disabled={!(rentalStatus===1)}>予約延長</CommonButton></Link>
          <Link to={"/rentals/extend-penalty/"+rentalId}><CommonButton  padding="4px" width="120px"  marginRight="4px" marginBottom="4px" disabled={!(rentalStatus===1)}>遅延予約</CommonButton></Link>
          <Link to={"/return/non-normal/"+rentalId} ><CancelButton padding="4px"   width="150px" marginBottom="4px"  disabled={!(rentalStatus===1)}>事故・不具合対応</CancelButton></Link>
          </ButtonGroup>
          <ButtonGroup>
          <CommonButton  width="120px" padding="4px" marginRight="4px" disabled={!(rentalStatus===0)}>予約変更</CommonButton>
          <CancelButton  onClick={onClickDelete!}width="120px" padding="4px"  disabled={!(rentalStatus===0)}>予約取消</CancelButton>
          </ButtonGroup>
        </PredictBox>
      </>
    );
  };
  

  const PredictBox = styled.div`
    display:flex ;
    flex-direction:column ;
    margin-bottom:10px;
    border: 2px solid ${color.Gray2};
    border-radius:5px;
    padding: 5px;
    font-size:12px;
    /* background-color:${color.Gray1} ; */
    width:100%;
  `;
  
  const ButtonGroup = styled.div`
    /* border: solid 1px; */
    display:flex ;
    flex-wrap:wrap;
    
  `;

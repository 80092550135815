import { useReducer } from "react";
import {
  inputStringType,
  inputNumberType,
  inputBooleanType,
  initailStringInput,
  initailNumberInput,
  initailBooleanInput,
} from "./reducerCommon";

export type BasicInfomationInitialStateType = {
  firstName: inputStringType;
  lastName: inputStringType;
  firstNameKana: inputStringType;
  lastNameKana: inputStringType;
  birthOfDate: inputStringType;
  prefecture: inputStringType;
  sikutyoson: inputStringType;
  address: inputStringType;
  phoneNumber: inputStringType;
  hasAccidentHistory: inputNumberType;
  hasPenaltyHistory: inputNumberType;
  mailAddress: inputStringType;
  password1: inputStringType;
  password2: inputStringType;
};

// type BasicInfomationAction = {
//   type: BasicInfomationActionType;
//   payload: BasicInfomationActionPayloadStringType;
// };

type BasicInfomationActionPayloadStringType = {
  inputValue?: string;
};

type BasicInfomationActionPayloadNumberType = {
  inputValue?: number;
};

export enum BasicInfomationActionType {
  ACTION_INPUT_FIRST_NAME = "ACTION_INPUT_FIRST_NAME",
  ACTION_INPUT_LAST_NAME = "ACTION_INPUT_LAST_NAME",
  ACTION_INPUT_FIRST_NAME_KANA = "ACTION_INPUT_FIRST_NAME_KANA",
  ACTION_INPUT_LAST_NAME_KANA = "ACTION_INPUT_LAST_NAME_KANA",
  ACTION_INPUT_BIRTH_OF_DATE = "ACTION_INPUT_BIRTH_OF_DATE",
  ACTION_INPUT_PREFECTURE = "ACTION_INPUT_PREFECTURE",
  ACTION_INPUT_SIKUTYOSON = "ACTION_INPUT_SIKUTYOSON",
  ACTION_INPUT_ADDRESS = "ACTION_INPUT_ADDRESS",
  ACTION_INPUT_PHONE_NUMBER = "ACTION_INPUT_PHONE_NUMBER",
  ACTION_INPUT_HAS_ACCIDENT_HISTORY = "ACTION_INPUT_HAS_ACCIDENT_HISTORY",
  ACTION_INPUT_HAS_PENALTY_HISTORY = "ACTION_INPUT_HAS_PENALTY_HISTORY",
  ACTION_INPUT_MAIL_ADDRESS = "ACTION_INPUT_MAIL_ADDRESS",
  ACTION_INPUT_PASSWORD1 = "ACTION_INPUT_PASSWORD1",
  ACTION_INPUT_PASSWORD2 = "ACTION_INPUT_PASSWORD2",
  ACTION_CHECK_BASIC_INFOMATION = "ACTION_CHECK_BASIC_INFOMATION",
}

type BasicInfomationAction =
  | {
      type: BasicInfomationActionType.ACTION_INPUT_FIRST_NAME;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_LAST_NAME;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_FIRST_NAME_KANA;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_LAST_NAME_KANA;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_BIRTH_OF_DATE;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_PREFECTURE;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_SIKUTYOSON;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_ADDRESS;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_PHONE_NUMBER;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_HAS_ACCIDENT_HISTORY;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_HAS_PENALTY_HISTORY;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_MAIL_ADDRESS;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_PASSWORD1;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_INPUT_PASSWORD2;
      payload: BasicInfomationActionPayloadStringType;
    }
  | {
      type: BasicInfomationActionType.ACTION_CHECK_BASIC_INFOMATION;
      payload: null;
    };

export const basicInfomationInitialState: BasicInfomationInitialStateType = {
  firstName: initailStringInput,
  lastName: initailStringInput,
  // val_errors:["入力してください"],
  firstNameKana: initailStringInput,
  lastNameKana: initailStringInput,
  birthOfDate: initailStringInput,
  prefecture: initailStringInput,
  sikutyoson: initailStringInput,
  address: initailStringInput,
  phoneNumber: initailStringInput,
  hasAccidentHistory: { value: 0, validation: [] },
  hasPenaltyHistory: { value: 0, validation: [] },
  mailAddress: initailStringInput,
  password1: initailStringInput,
  password2: initailStringInput,
};

// 本質的にstateとactionを引数に持つ関数で、returnで新しいstateを返せばいい。
export const basicInfomationReducer: React.Reducer<
  BasicInfomationInitialStateType,
  BasicInfomationAction
> = (state, action) => {
  let validation: string[] = [];
  switch (action.type) {
    case BasicInfomationActionType.ACTION_INPUT_FIRST_NAME: {
      if (action.payload.inputValue === "") {
        validation.push("必須です。");
      }
      return {
        ...state,
        firstName: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }
    case BasicInfomationActionType.ACTION_INPUT_LAST_NAME: {
      if (action.payload.inputValue === "") {
        validation.push("必須です。");
      }
      return {
        ...state,
        lastName: { value: action.payload.inputValue!, validation: validation },
      };
    }
    case BasicInfomationActionType.ACTION_INPUT_FIRST_NAME_KANA: {
      if (action.payload.inputValue === "") {
        validation.push("必須です。");
      }
      return {
        ...state,
        firstNameKana: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }
    case BasicInfomationActionType.ACTION_INPUT_LAST_NAME_KANA: {
      if (action.payload.inputValue === "") {
        validation.push("必須です。");
      }
      return {
        ...state,
        lastNameKana: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }
    case BasicInfomationActionType.ACTION_INPUT_BIRTH_OF_DATE: {
      if (action.payload.inputValue === "") {
        validation.push("必須です");
      }
      let regexp = /^[1-2][0-9][0-9][0-9][10][0-9][0-3][0-9]$/g;
      if (!regexp.test(String(action.payload.inputValue!))) {
        validation.push("8桁の数字で正しい生年月日を入力してください");
      }
      return {
        ...state,
        birthOfDate: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }
    case BasicInfomationActionType.ACTION_INPUT_PREFECTURE: {
      if (action.payload.inputValue === "") {
        validation.push("必須です");
      }
      return {
        ...state,
        prefecture: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_SIKUTYOSON: {
      if (action.payload.inputValue === "") {
        validation.push("必須です");
      }
      return {
        ...state,
        sikutyoson: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_ADDRESS: {
      if (action.payload.inputValue === "") {
        validation.push("必須です");
      }
      return {
        ...state,
        address: { value: action.payload.inputValue!, validation: validation },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_PHONE_NUMBER: {
      if (action.payload.inputValue === "") {
        validation.push("必須です");
      }
      return {
        ...state,
        phoneNumber: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_HAS_ACCIDENT_HISTORY: {
      return {
        ...state,
        hasAccidentHistory: {
          value: Number(action.payload.inputValue!),
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_HAS_PENALTY_HISTORY: {
      return {
        ...state,
        hasPenaltyHistory: {
          value: Number(action.payload.inputValue!),
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_MAIL_ADDRESS: {
      return {
        ...state,
        mailAddress: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_PASSWORD1: {
      if (action.payload.inputValue === "") {
        validation.push("必須です。");
      }
      return {
        ...state,
        password1: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    case BasicInfomationActionType.ACTION_INPUT_PASSWORD2: {
      if (action.payload.inputValue !== state.password1.value) {
        validation.push("パスワードが異なっています。");
      }
      return {
        ...state,
        password2: {
          value: action.payload.inputValue!,
          validation: validation,
        },
      };
    }

    // バリデーション用 もうちょい簡潔に楽にかけただろうなぁ。。。
    case BasicInfomationActionType.ACTION_CHECK_BASIC_INFOMATION: {
      let firstNameValidation = []
      let lastNameValidation = []
      let firstNameKanaValidation = []
      let lastNameKanaValidation = []
      let birthOfDateValidation = []
      let prefectureValidation = []
      let sikutyosonValidation = []
      let addressValidation = []
      let phoneNumberValidation = []
      let password1Validation = []
      let password2Validation = []
      if (state.firstName.value === "") {
        firstNameValidation.push("必須です");
      }
      if (state.lastName.value === "") {
        console.log("テスト")
        lastNameValidation.push("必須です");
      }
      if (state.firstNameKana.value === "") {
        firstNameKanaValidation.push("必須です");
      }
      if (state.lastNameKana.value === "") {
        lastNameKanaValidation.push("必須です");
      }
      if (state.birthOfDate.value === "") {
        birthOfDateValidation.push("必須です");
      }
      if (state.prefecture.value === "") {
        prefectureValidation.push("必須です");
      }
      if (state.sikutyoson.value === "") {
        sikutyosonValidation.push("必須です");
      }
      if (state.address.value === "") {
        addressValidation.push("必須です");
      }
      if (state.phoneNumber.value === "") {
        phoneNumberValidation.push("必須です");
      }
      if (state.password1.value === "") {
        password1Validation.push("必須です");
      }
      // 一致性のやつは必然的に上でチェックされる。
      if (state.password2.value === "") {
        password2Validation.push("必須です");
      }
      // console.log(validation)
      
      return {
        ...state,
        firstName:{
          value:state.firstName.value,
          validation:firstNameValidation
        },
        lastName:{
          value:state.lastName.value,
          validation:lastNameValidation
        },
        firstNameKana:{
          value:state.firstNameKana.value,
          validation:firstNameKanaValidation
        },
        lastNameKana:{
          value:state.lastNameKana.value,
          validation:lastNameKanaValidation
        },
        birthOfDate:{
          value:state.birthOfDate.value,
          validation:birthOfDateValidation
        },
        prefecture:{
          value:state.prefecture.value,
          validation:prefectureValidation
        },
        sikutyoson:{
          value:state.sikutyoson.value,
          validation:sikutyosonValidation
        },
        address:{
          value:state.address.value,
          validation:addressValidation
        },
        phoneNumber:{
          value:state.phoneNumber.value,
          validation:phoneNumberValidation
        },
        password1:{
          value:state.password1.value,
          validation:password1Validation
        },
        password2:{
          value:state.password2.value,
          validation:password2Validation
        }
      };
    }

    default:
      return basicInfomationInitialState;
  }
};

export type CarLicenseStateType = {
  carLicenseImage: inputStringType;
  carLicenseImageS3Path: inputStringType;
  licenseYear: inputNumberType;
  licenseMonth: inputNumberType;
  licenseDay: inputNumberType;
  licenseAddressCheck: { value: boolean; validation: [] };
};

export const CarLicenseInitialStateValue: CarLicenseStateType = {
  carLicenseImage: initailStringInput,
  licenseYear: initailNumberInput,
  licenseMonth: initailNumberInput,
  licenseDay: initailNumberInput,
  licenseAddressCheck: { value: true, validation: [] },
  carLicenseImageS3Path: initailStringInput,
};

enum CarLicenseActionType {
  ACTION_INPUT_CAR_LICENSE_IMAGE = "ACTION_INPUT_CAR_LICENSE_IMAGE",
  ACTION_INPUT_CAR_LICENSE_IMAGE_S3_PATH = "ACTION_INPUT_CAR_LICENSE_IMAGE_S3_PATH",
  ACTION_INPUT_CAR_LICENSE_YEAR = "ACTION_INPUT_CAR_LICENSE_YEAR",
  ACTION_INPUT_CAR_LICENSE_MONTH = "ACTION_INPUT_CAR_LICENSE_MONTH",
  ACTION_INPUT_CAR_LICENSE_DAY = "ACTION_INPUT_CAR_LICENSE_DAY",
  ACTION_INPUT_CAR_LICENSE_ADDRESS_CHECK = "ACTION_INPUT_CAR_LICENSE_ADDRESS_CHECK",
}

// type CarLicenseAction = {
//   type: CarLicenseActionType;
//   payload: CarLicenseActionPayloadType;
// };

type CarLicenseActionPayloadStringType = {
  inputValue?: string;
};

type CarLicenseActionPayloadNumberType = {
  inputValue?: number;
};

type CarLicenseAction =
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_IMAGE;
      payload: CarLicenseActionPayloadStringType;
    }
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_IMAGE_S3_PATH;
      payload: CarLicenseActionPayloadStringType;
    }
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_YEAR;
      payload: CarLicenseActionPayloadNumberType;
    }
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_DAY;
      payload: CarLicenseActionPayloadNumberType;
    }
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_MONTH;
      payload: CarLicenseActionPayloadNumberType;
    }
  | {
      type: CarLicenseActionType.ACTION_INPUT_CAR_LICENSE_ADDRESS_CHECK;
    };

export const carLisenceReducer: React.Reducer<
  CarLicenseStateType,
  CarLicenseAction
> = (state, action) => {
  let validation:string[] = []
  switch (action.type) {
    case "ACTION_INPUT_CAR_LICENSE_IMAGE": {
      return {
        ...state,
        carLicenseImage: { value: action.payload.inputValue!, validation: [] },
      };
    }
    case "ACTION_INPUT_CAR_LICENSE_IMAGE_S3_PATH": {
      return {
        ...state,
        carLicenseImageS3Path: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case "ACTION_INPUT_CAR_LICENSE_YEAR": {
      let regexp = /^[1-2][0-9][0-9][0-9]$/g;
      if (!regexp.test(String(action.payload.inputValue!))) {
        validation.push("４桁の数字で入力してください");
      }
      console.log("a")
      return {
        ...state,
        licenseYear: { value: action.payload.inputValue!, validation: validation },
      };
    }
    case "ACTION_INPUT_CAR_LICENSE_MONTH": {
      let regexp = /^[01][0-9]$/g;
      if (!regexp.test(String(action.payload.inputValue!))) {
        validation.push("2桁の数字で入力してください");
      }
      return {
        ...state,
        licenseMonth: { value: action.payload.inputValue!, validation: validation },
      };
    }
    case "ACTION_INPUT_CAR_LICENSE_DAY": {
      let regexp = /^[1-3][0-9]$/g;
      if (!regexp.test(String(action.payload.inputValue!))) {
        validation.push("2桁の数字で入力してください");
      }
      return {
        ...state,
        licenseDay: { value: action.payload.inputValue!, validation: validation },
      };
    }
    case "ACTION_INPUT_CAR_LICENSE_ADDRESS_CHECK": {
      return {
        ...state,
        licenseAddressCheck: {
          value: !state.licenseAddressCheck.value,
          validation: [],
        },
      };
    }
    default:
      return CarLicenseInitialStateValue;
  }
};

export type PlanStateType = {
  plan: inputStringType;
};

export const PlanInitialState: PlanStateType = {
  plan: { value: "basic", validation: [] },
};

enum PlanActionType {
  ACTION_SELECT_PLAN = "ACTION_SELECT_PLAN",
}

type PlanAction = {
  type: PlanActionType.ACTION_SELECT_PLAN;
  payload: PlanActionPayloadType;
};

type PlanActionPayloadType = {
  inputValue?: string;
};

export const PlanReducer: React.Reducer<PlanStateType, PlanAction> = (
  state,
  action
) => {
  switch (action.type) {
    case PlanActionType.ACTION_SELECT_PLAN: {
      return {
        ...state,
        plan: { value: action.payload.inputValue!, validation: [] },
      };
    }
    default:
      return PlanInitialState;
  }
};

import styled from "styled-components";
import * as color from "../utils/color";
import { useState, useRef, useEffect } from "react";
import { Camera, CameraType } from "react-camera-pro";
import { Container } from "../utils/common";
import { api } from "../api";
import axios from "axios";

const toBlob= (base64:string)=> {
  console.log("ブロブをする")
  var bin = atob(base64.replace(/^.*,/, ''));
  var buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
      buffer[i] = bin.charCodeAt(i);
  }
  // Blobを作成
  try{
      var blob = new Blob([buffer.buffer], {
          type: 'image/jpeg'
      });
  }catch (e){
      console.log(e)
      return false;
  }
  console.log("ブロブを返せた")
  return blob;
}

export const TakePhoto = ({
  image,
  setImage,
  // オフにするため。
  setCameraMode,
  setImageS3path
}: {
  image?: any;
  setImage: (v: any) => any;
  setCameraMode: (v: any) => any;
  // s3にアップロードをする場合。
  imageS3Path?: string;
  setImageS3path?: (v: any) => any;
}) => {
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [showImage, setShowImage] = useState<boolean>(false);
  const camera = useRef<CameraType>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [activeDeviceId, setActiveDeviceId] = useState<string | undefined>(
    undefined
  );
  const [preSignedUrl, setPresignedUrl] = useState<string>();

  // カメラデバイス一覧を取得する処理
  useEffect(() => {
    console.log("tes")
    ;(async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind == "videoinput");
      setDevices(videoDevices);
    })();
  }, []);

  // pre-signed-urlを取得してアップロードを行う処理。写真を撮影する最初に稼働するとする。
  useEffect(() => {
    console.log("トリガー");
    if(!!preSignedUrl) return
    console.log("トリガー2");
    // console.log(!!image)
    // if(!image) return
    (async () => {
      api("GET s3/get-presigned-url/", null)
        .then((res) => {
          // url
          setPresignedUrl(res.data.url)
          setImageS3path?.(res.data.path)
          console.log("1")
        })
        .catch((err) => {
          console.log("s3urlの取得に失敗");
          console.log(err);
        });
    })();
  }, []);

  return (
    <Container>
      <CameraWrapper>
        <Camera
          ref={camera}
          aspectRatio="cover"
          numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
          videoSourceDeviceId={activeDeviceId}
          errorMessages={{
            noCameraAccessible: "この端末ではカメラが使えません。",
            permissionDenied: "カメラに対するアクセス権限がありません",
            switchCamera: "この端末ではスイッチできません。",
            canvas: "この端末のこのブラウザにはキャンバスが対応していません。",
          }}
        />
      </CameraWrapper>

      {/* 撮影画面下部 */}
      <Control>
        {/* カメラデバイスの選択。 */}
        <select
          onChange={(event) => {
            setActiveDeviceId(event.target.value);
          }}
        >
          {devices.map((d) => (
            <option key={d.deviceId} value={d.deviceId}>
              {d.label}
            </option>
          ))}
        </select>
        {/* 撮影画面の右下の小さい画像 */}
        <ImagePreview
          image={image}
          onClick={() => {
            setShowImage(!showImage);
          }}
        />
        {/* 撮影画面における写真を取るボタン */}
        <TakePhotoButton
          onClick={() => {
            if (camera.current) {
              const photo = camera.current.takePhoto();
              console.log(photo);
              setImage(photo);
              setCameraMode(false);
              axios({
                method: "PUT",
                //   url: url+filename,
                url: preSignedUrl,
                data: toBlob(photo!)!,
              })
                .then((r) => {
                  console.log(r);
                  console.log("アップロードを行いました。");
                })
                .catch((err) => {
                  console.log(err);
                  console.log("アップロードに失敗");
                });
            }
          }}
        />
        {/* 前と後ろを交代するボタン */}
        <ChangeFacingCameraButton
          disabled={numberOfCameras <= 1}
          onClick={() => {
            if (camera.current) {
              const result = camera.current.switchCamera();
              console.log(result);
            }
          }}
        />
      </Control>
    </Container>
  );
};

const CameraWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
`;

const Control = styled.div`
  position: fixed;
  display: flex;
  right: 0;
  width: 20%;
  min-width: 130px;
  min-height: 130px;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column-reverse;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: row;
    bottom: 0;
    width: 100%;
    height: 20%;
  }
  @media (max-width: 400px) {
    padding: 10px;
  }
`;

const Button = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  background-color: transparent;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-image: none;
  padding: 0;
  text-shadow: 0px 0px 4px black;
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: auto;
  cursor: pointer;
  z-index: 2;
  filter: invert(100%);
  border: none;
  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled(Button)`
  background: url("https://img.icons8.com/ios/50/000000/compact-camera.png");
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ChangeFacingCameraButton = styled(Button)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 0;
    cursor: default;
    padding: 60px;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
    &:disabled {
      padding: 40px 25px;
    }
  }
`;

const ImagePreview = styled.div<{ image: string | null }>`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : "")}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

import * as React from "react";
import {Row,RowContent,RowTitle,Container} from "../../utils/common"
import {BasicInfomationInitialStateType,
  CarLicenseStateType,
  PlanStateType
}  from "../../reducer/registerUserReducer"

import { CameraImage } from "../../utils/common";

type Props = {
  basicInfomationState:BasicInfomationInitialStateType,
  carLicenseState:CarLicenseStateType,
  // planState:PlanStateType
  owner:string;
};


export const RegisterView = ({basicInfomationState,carLicenseState,owner}:Props)=> {

  return (
    <Container>
      <Row>
        <RowTitle>姓</RowTitle>
        <RowContent>
          {basicInfomationState.firstName.value}

        </RowContent>
      </Row>
      <Row>
        <RowTitle>名</RowTitle>
        <RowContent>
        {basicInfomationState.lastName.value}
          
        </RowContent>
      </Row>
      <Row>
        <RowTitle>姓（ふりがな）</RowTitle>
        <RowContent>
        {basicInfomationState.firstNameKana.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>名（ふりがな）</RowTitle>
        <RowContent>
        {basicInfomationState.lastNameKana.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>生年月日</RowTitle>
        <RowContent>
        {basicInfomationState.birthOfDate.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>都道府県</RowTitle>
        <RowContent>
        {basicInfomationState.prefecture.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>市区町村</RowTitle>
        <RowContent>
        {basicInfomationState.sikutyoson.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>上記以外の住所</RowTitle>
        <RowContent>
        {basicInfomationState.address.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>メールアドレス</RowTitle>
        <RowContent>
        {basicInfomationState.mailAddress.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>携帯電話番号</RowTitle>
        <RowContent>
        {basicInfomationState.phoneNumber.value}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>違反歴</RowTitle>
        <RowContent>
        {basicInfomationState.hasPenaltyHistory.value ? "違反歴あり":"違反歴なし"}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>事故歴の有無</RowTitle>
        <RowContent>
        {basicInfomationState.hasAccidentHistory.value ? "事故歴あり":"事故歴なし"}
        </RowContent>
      </Row>

      <Row>
        <RowTitle>免許証画像</RowTitle>
        <RowContent>
        <CameraImage src={carLicenseState.carLicenseImage.value as string} alt="免許証"/>
        </RowContent>
      </Row>
      {/* テスト用 */}
      {/* <Row>
        <RowTitle>画像パス</RowTitle>
        <RowContent>
        {carLicenseState.carLicenseImageS3Path.value}
        </RowContent>
      </Row> */}

      <Row>
        <RowTitle>免許証記載住所から変更がない</RowTitle>
        <RowContent>
        {carLicenseState.licenseAddressCheck.value ? <>なし</>:<>あり</>}
        </RowContent>
      </Row>

      <Row>
        <RowTitle>免許証有効期限</RowTitle>
        <RowContent>
        {carLicenseState.licenseYear.value}/{carLicenseState.licenseMonth.value}/{carLicenseState.licenseDay.value}
        </RowContent>
      </Row>

      {/* <Row>
        <RowTitle>プラン</RowTitle>
        <RowContent>
        {planState.plan.value==="platinum" && <>プラチナ会員</>}
        {planState.plan.value==="gold" && <>ゴールド会員</>}
        {planState.plan.value==="basic" && <>ベーシック会員</>}
        </RowContent>
      </Row> */}
      
      
    </Container>
  );
}
;

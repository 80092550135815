import { useState } from "react";
import { TakePhoto } from "../components/TakePhoto";
import axios from "axios";
import { Buffer } from "buffer";
import Logo from "../sozai/logo.jpg";
import styled from "styled-components";

const toBlob= (base64:string)=> {
    var bin = atob(base64.replace(/^.*,/, ''));
    var buffer = new Uint8Array(bin.length);
    for (var i = 0; i < bin.length; i++) {
        buffer[i] = bin.charCodeAt(i);
    }
    // Blobを作成
    try{
        var blob = new Blob([buffer.buffer], {
            type: 'image/jpg'
        });
    }catch (e){
        return false;
    }
    return blob;
}

export const ImageUpload = () => {
  const [image, setImage] = useState();
  const [cameraMode, setCameraMode] = useState(false);

  const handleFiles = (e: any) => {
    let files = e.currentTarget.files;
    if (!files || files?.length === 0) return;
    const file = files[0];
    console.log(file);
    setImage(file);
    // console.log(e.target.value)
  };

  //   let filename="/test.jpg"
  let preSignedUrl = "";
  const upload = async () => {
    preSignedUrl="https://car-sharing-images.s3.amazonaws.com/testes.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVDQ2FV5ZOZCOPO7L%2F20221102%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20221102T074844Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=5f3ac445697ac29ec72c35b6cabbd17203d01342a29474a2707c54f28524e2a6"
        console.log("ファイルをアップロードする。");
        console.log(preSignedUrl);

         let data =toBlob(image!)
        // let data = image!;
        console.log(data);

        axios({
          method: "PUT",
          //   url: url+filename,
          url: preSignedUrl,
          data: data,
        })
          .then((r) => {
            console.log(r);
            console.log("アップロードを行いました。");
          })
          .catch((err) => {
            console.log(err);
            console.log("アップロードに失敗");
          });
    // preSignedUrl =
    // テスト中のため生
    // axios
    //   .get("http://localhost:8008/s3/get-presined-url/")
    //   .then((res: any) => {
    //     // preSignedUrl = res.data.url;
    //     preSignedUrl="https://car-sharing-images.s3.amazonaws.com/testes.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVDQ2FV5ZOZCOPO7L%2F20221102%2Fap-northeast-1%2Fs3%2Faws4_request&X-Amz-Date=20221102T074844Z&X-Amz-Expires=3600&X-Amz-SignedHeaders=host&X-Amz-Signature=5f3ac445697ac29ec72c35b6cabbd17203d01342a29474a2707c54f28524e2a6"
    //     console.log("ファイルをアップロードする。");
    //     console.log(preSignedUrl);

    //      let data =toBlob(image!)
    //     // let data = image!;
    //     console.log(data);

    //     axios({
    //       method: "PUT",
    //       //   url: url+filename,
    //       url: preSignedUrl,
    //       data: data,
    //     })
    //       .then((r) => {
    //         console.log(r);
    //         console.log("アップロードを行いました。");
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         console.log("アップロードに失敗");
    //       });
    //   })
    //   .catch((err) => {});
  };

  return (
    <>
      <div>画像のアップロードのテスト</div>
      {!cameraMode && <button onClick={() => setCameraMode(true)}>撮影</button>}
      {cameraMode && (
        <TakePhoto
          image={image}
          setImage={setImage}
          setCameraMode={setCameraMode}
        ></TakePhoto>
      )}
      {image && <ImageItem src={image} alt="" />}
      <button
        onClick={() => {
          upload();
        }}
      >
        アップロード
      </button>

      <input type="file" accept="image/*" onChange={handleFiles} />
    </>
  );
};

const ImageItem = styled.img`
width:300px;
margin-left:10px;
`
import { useState, Dispatch } from "react";
import { CommonButton } from "../utils/Button";
import { TakePhoto } from "./TakePhoto";
import { CommonTextArea } from "../utils/TextField";
import { RadioGroup } from "../utils/Radio";
import { ReqAndRes } from "../api";
import {
  UseCarAction,
  UseCarActionType,
  UseCarStateType,
} from "../reducer/useCarReducer";
import styled from "styled-components";

export const InputCarDamage = ({
  onClickNext,
  useCarState,
  useCardispatch,
  useCarAPI
}: {
  onClickNext?: () => void;
  useCardispatch?: Dispatch<UseCarAction>;
  useCarState?: UseCarStateType;
  useCarAPI?:()=>void
}) => {
  const [isExistScratch, setIsExistScratch] = useState(1);
  const [image, setImage] = useState();
  const [text, SetText] = useState("");
  const [cameraMode, setCameraMode] = useState(false);
  // （いいえが選択されている　または　画像とテキストが両方入力されている）
  const isOKNext =
    !isExistScratch ||
    (!!useCarState?.damageReportText?.value &&
      !!useCarState?.damageReportImage?.value);
  return (
    <>
      <CommonButton
        onClick={() => {
          console.log(useCarState);
        }}
      >
        テスト
      </CommonButton>
      <div>
        すでに外観に傷がある。
        <RadioGroup
          radioProps={[
            { value: "1", label: "はい" },
            { value: "0", label: "いいえ" },
          ]}
          defaultValue={String(isExistScratch)}
          onChange={(e) => {
            setIsExistScratch(Number(e.target.value));
          }}
        />
      </div>
      {isExistScratch && (
        <>
          <div>傷について記述してください。（はいの場合は必須）</div>
          <CommonTextArea
            value={useCarState?.damageReportText?.value}
            onChange={(e) => {
              useCardispatch!({
                type: UseCarActionType.ACTION_INPUT_DAMAGE_REPORT_TEXT,
                payload: { inputValue: e.target.value },
              });
            }}
          />
          <div>傷の拡大写真を撮影してください。（はいの場合は必須）</div>
          <CommonButton
            onClick={() => {
              setCameraMode(true);
            }}
          >
            撮影ボタン
          </CommonButton>
          {cameraMode && (
            <TakePhoto
              image={image}
              setImage={setImage!}
              setCameraMode={setCameraMode}
              setImageS3path={(v) => {
                useCardispatch!({
                  type: UseCarActionType.ACTION_INPUT_DAMAGE_REPORT_IMAGE,
                  payload: { inputValue: v },
                });
              }}
            />
          )}
          {image && <MyImage src={image} />}
        </>
      )}
      <CommonButton
        disabled={!isOKNext}
        onClick={() => {
          useCarAPI!();
        }}
      >
        車の鍵を収納するキーボックス解錠の暗証番号を発行する
      </CommonButton>
    </>
  );
};

const MyImage = styled.img`
  /* transform: rotate( 90deg ); */
  width:300px;
`;

import styled from "styled-components";
import * as color from "./color";

export const CommonButton = styled.button<{
  width?: string;
  margin?: string;
  marginRight?: string;
  marginLeft?: string;
  marginBottom?: string;
  marginTop?: string;
  padding?: string;
}>`
  font-size: 8px;
  color: ${color.Black};
  background-color: ${color.White};
  padding: 8px;
  border: solid 2px ${color.Gray2};
  border-radius: 4px;
  flex-grow: 0;
  ${({ width }) => (width ? `width:${width};` : `width:100%;`)}
  ${({ margin }) => (margin ? `margin:${margin};` : `;`)}
  ${({ marginRight }) => (marginRight ? `margin-right:${marginRight};` : `;`)}
  ${({ marginLeft }) => (marginLeft ? `margin-left:${marginLeft};` : `;`)}
  ${({ marginBottom }) => (marginBottom ? `margin-bottom:${marginBottom};` : `;`)}
  ${({ marginTop }) => (marginTop ? `margin-top:${marginTop};` : `;`)}


  ${({ padding }) => (padding ? `padding:${padding};` : `;`)}
  :disabled {
    background-color: ${color.Gray2};
    border: solid 2px ${color.Gray6};
  }
`;

export const CancelButton = styled(CommonButton)`
  /* background-color: ${color.CanselColor}; */
  border: solid 2px ${color.CanselColor};
`;

export const AgreeButton = styled(CommonButton)`
  /* background-color: ${color.AgreeColor}; */
  border: solid 2px ${color.AgreeColor};

  
`;

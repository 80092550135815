import { Login } from "./pages/login";
import { Register } from "./pages/Register/main";
import { Header } from "./components/Header";
import { RegisterFinish } from "./pages/Register/registerFinish";
import { Top } from "./pages/Top";
import { RentalList } from "./pages/RentalList";
import { RegisterModify } from "./pages/ConfirmAndModifyUserInfo/RegisterModify";
import { NewRental } from "./pages/ReservationCar/main";
import { UseCar } from "./pages/UseCar/main";
import { ReturnCar } from "./pages/ReturnCar/main";
import { ExtendReservation } from "./pages/ExtendReservation/main";
import { ExtendPenaltyReservation } from "./pages/ExtendPenaltyReservation/main";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import { ImageUpload } from "./pages/ImageUploadTest";
import { useEffect, useState } from "react";
import axios from "axios";
import { BaseURL } from "./setting";
import dayjs from "dayjs";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { reduxStateType } from "./reduxReducer";
import { NonNormalReturn } from "./pages/ReturnCar/NonNormalReturn";
import { ServiceInfomation } from "./pages/ServiceInfomation";


const Layout = () => {
  // 前提。後で実装する。
  // これはグローバル変数である必要がある。
  // ログインに成功したらクッキーにセッションを残す。
  // const [isLogined, setIsLogined] = useState(false);
  const isLogin = useSelector(
    (state: reduxStateType) => state.isLogin,
    shallowEqual
  );
  // const isLogin = true
  const disptch = useDispatch();

  useEffect(() => {
    // console.log("ログインされているか");
    console.log(isLogin);
    if (isLogin) return;
    // console.log("ログインされていなかったあとの世界。");
    // もしログイン状態でない（グローバル変数で）ならば、。。。という前提のもとで。
    let accessToken = localStorage.getItem("accessToken");
    // アクセストークンが有効だったら、そのままログインさせる。
    if (!!accessToken) {
    }
    (async () => {
      await axios
        .post(BaseURL + "auth/token/verify/", { token: accessToken })
        .then(() => {
          // console.log("verify成功");
          disptch({ type: "Login.SetLogin" });
        })
        .catch(() => {
          // console.log("verify失敗");
          // リフレッシュトークンを用いる？
          let refreshToken = localStorage.getItem("refreshToken");
          axios
            .post(BaseURL + "auth/token/refresh/", { refresh: refreshToken })
            .then((refreshResponse: any) => {
              // console.log("リレッシュ成功")
              // console.log(refreshResponse.data.access)
              localStorage.setItem("accessToken", refreshResponse.data.access);
              // console.log(refreshResponse.data.refresh)
              localStorage.setItem(
                "refreshToken",
                refreshResponse.data.refresh
              );
              disptch({ type: "Login.SetLogin" });
            })
            .catch((err) => {
              console.log(err);
            });
        });
    })();
    // クッキーの中でアクセストークンが残っているかをチェック。
    // アクセストークンが有効かどうかをチェック。

    // ログインしたときはアクセストークンを
    // setIsLogined(true)
  }, []);
  // ログインされているかをチェック
  if (isLogin !== true) {
    console.log("ログインされていないのでログインページにリダイレクトします。");
    // console.log(localStorage)
    return <Login />;
  }

  return (
    <>
      {/* ここにログインしているかどうかをいれる。
       */}
      <Header />
      <Outlet />
    </>
  );
};

// const authLoader = () => {
//   if (!isAuthenticated()) {
//     return redirect("https://google.com");
//   } else {
//     return getUser();
//   }
// };

const router = createBrowserRouter([
  {
    children: [
      {
        path: "/login/",
        element: <Login />,
        // loader: authLoader
      },
      {
        path: "/service/",
        element: <ServiceInfomation />,
        // loader: authLoader
      },
      // 登録確認メール
      {
        path: "/sign_up_confirm/",
        element: <Register />,
      },
      {
        path: "/confirm_email/",
        element: <Register />,
      },

      {
        path: "/register/",
        element: <Register />,
      },
      {
        path: "/register/finish",
        element: <RegisterFinish />,
      },
      {
        path: "/",
        element: <Layout />,
        children: [
          
          {
            path: "/",
            element: <Top />,
          },
          {
            path: "/test/image",
            element: <ImageUpload />,
          },
          {
            path: "/rentals",
            element: <RentalList />,
          },
          {
            path: "/rentals/extend/:reservationId",
            element: <ExtendReservation />,
          },
          {
            path: "/rentals/extend-penalty/:reservationId",
            element: <ExtendPenaltyReservation />,
          },
          
          {
            path: "/register/confirm/",
            element: <RegisterModify />,
          },
          {
            path: "/rentals/new",
            element: <NewRental />,
          },
          {
            path: "/use/:reservationId",
            element: <UseCar />,
          },
          {
            path: "/return/:reservationId",
            element: <ReturnCar />,
          },
          {
            path: "/return/non-normal/:reservationId",
            element: <NonNormalReturn />,
          },
        ],
      },
    ],
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;

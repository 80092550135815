import { Container } from "../../utils/common";

export const RegisterFinish = () => {

  return (
    <Container>
      登録完了<br/>
      審査が行われます。審査が完了次第、メール通知にて通知いたします。<br/>
      １週間以内に審査完了のメールが届かない場合は、お手数ですが管理人にご連絡ください。
    </Container>
  );
};
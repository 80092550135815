import { Container } from "../utils/common";
import { UseCarCard } from "../components/UseCarCard";
import { useState, useEffect } from "react";
import { api, ReqAndRes } from "../api";

export const RentalList = () => {
  const [reservation, setReservation] = useState<
    ReqAndRes["GET usages/reservations/"]["res"]["data"]["results"]
  >([]);
  useEffect(() => {
    (async () => {
      await api("GET usages/reservations/", {limit:10})
        .then((res) => {
          console.log(res.data);
          setReservation(res.data.results);
        })
        .catch((err) => {
          // 処理
        });
    })();
  }, []);
  return (
    <Container>
      過去10件までの利用履歴
      {!!reservation &&
        reservation.map((v, index) => {
          return (
            <UseCarCard
              key={index}
              rentalId={v.id}
              carName={v.car.name}
              carPlace={v.car.parkingAddress}
              useStartDate={v.startReservationDateTime}
              useEndDate={v.endReservationDateTime}
              prePoint={v.point.lastPoint + v.point.pointDiff}
              consumedPoint={v.point.pointDiff}
              postPoint={v.point.lastPoint}
              rentalStatus={v.status}
            />
          );
        })}
    </Container>
  );
};

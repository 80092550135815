import { useEffect, useState } from "react";
import { CommonButton } from "../../utils/Button";
import { api, ReqAndRes } from "../../api";
import { Link, Navigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { CommonSelect } from "../../utils/Select";

export const selectTimeData = [
    { label: "追加利用時間を選択してください", value: 0 },
    { label: "2時間", value: 2 },
    { label: "4時間", value: 4 },
    { label: "6時間", value: 6 },
    { label: "8時間", value: 8 },
    { label: "10時間", value: 10 },
    { label: "12時間", value: 12 },
    // { label: "14時間", value: 14 },
    // { label: "16時間", value: 16 },
  ];

export const ExtendPenaltyReservation = () => {
  // 処理
  let { reservationId } = useParams();
  // const [maxAvailableHour,setMaxAvailableHour] = useState()
  const [isAbleToExtend, setIsAbleToExtend] = useState<Boolean>(false);
  const [useTime,setUseTime]= useState(2)
  const [isFinish,setIsFinish]=useState(false)
  const handleChange = (e: any) => {
    // console.log(e.target.value)
    setUseTime(e.target.value);
    (async () => {
        api("GET usages/reservations/extend-penalty/", { id: Number(reservationId), useTime:Number(e.target.value) })
          .then((res) => {
            setIsAbleToExtend(true);
            // 予約が取得できている状況。
            // setReservation(res.data)
            setData(res.data);
          })
          .catch((err) => {
            // エラーページにとばしたほうがいいかも。。
          });
      })();
  };

  const [data, setData] =
    useState<ReqAndRes["GET usages/reservations/extend/"]["res"]["data"]>();
  const extendReservation = () => {
    (async () => {
        api("POST usages/reservations/extend-penalty/", { id: Number(reservationId),useTime:useTime })
          .then((res) => {
            console.log("成功")
            // setIsFinish(true)
          })
          .catch((err) => {
            // エラーページにとばしたほうがいいかも。。
          });
      })();
  };



  if(isFinish) {
    return <Navigate replace to="/"/>
  }

  return (
    <>
      <h2>延長予約申請画面</h2>
      <CommonSelect
        selectProps={selectTimeData}
        onChange={(e) => handleChange(e)}
        width="100%"
      />
      {isAbleToExtend ? (
        <>
          <div>
            開始時間:
            {dayjs(data?.startReservationDateTime).format("YYYY-MM-DD HH:mm")}
          </div>
          <div>
            終了時間:
            {dayjs(data?.endReservationDateTime).format("YYYY-MM-DD HH:mm")}
          </div>
          <div>利用時間:{useTime}</div>
          <div>現在のポイント:{data?.lastPoint}</div>
          <div>消費ポイント:{data?.usePoint}</div>
          <div>
            利用後ポイント:{data?.lastPoint! - data?.usePoint!}
            (0以下の場合は予約できません。)
          </div>
          <p>(※ポイント残高によっては、オーナーより別途延滞料金の請求がきますので締結規約通りのお支払いを願います。)</p>
          <CommonButton disabled={data?.lastPoint! - data?.usePoint! < 0} onClick={extendReservation}>
            確定
          </CommonButton>
          <Link to="/">
            <CommonButton>戻る</CommonButton>
          </Link>
        </>
      ) : (
        <>
          <br/>
          <Link to="/">
            <CommonButton>戻る</CommonButton>
          </Link>
        </>
      )}
    </>
  );
};

// const Title =

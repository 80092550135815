import { CommonButton as _CommonButton } from "../../utils/Button";
import styled from "styled-components";
import { useState } from "react";
import { RadioGroup } from "../../utils/Radio";
import { CommonTextArea, CommonTextField } from "../../utils/TextField";
import dayjs from "dayjs";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { Navigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { api,ReqAndRes } from "../../api";

export const CannnotReturnForPenalty = ({reservationId,car}:{reservationId:number,car:ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"]}) => {
  let today = dayjs().format("YYYY-MM-DD");
  let time = dayjs().format("HH:mm");

  const [penaltyDate, setPenaltyDate] = useState(today);
  const [penaltyTime, setPenaltyTime] = useState(time);
  const [penaltyText, setPenaltyText] = useState();
  const [pickUpPlaceName, setPickUpPlaceName] = useState("");
  const [policeManagerName, setPoliceManagerName] = useState("");
  const [policePlacePhoneNumber, setPolicePlacePhoneNumber] = useState("");
  const [activeStep, setActiveStep] = React.useState(0);
  const [finish,setFinish]=useState(false)
  const steps = [
    {
      label: "警察取締の日付を入力してください。",
      description: (
        <>
          <CommonTextField
            value={penaltyDate}
            onChange={(e) => {
              setPenaltyDate(e.target.value);
            }}
            type="date"
          ></CommonTextField>
        </>
      ),
    },
    {
      label: "警察取締の時刻を入力してください",
      description: (
        <>
          <CommonTextField
            type="time"
            value={penaltyTime}
            onChange={(e) => {
              setPenaltyTime(e.target.value);
            }}
          ></CommonTextField>
        </>
      ),
    },

    {
      label: "違反内容を記入してください。",
      description: (
        <>
          <CommonTextArea
            value={penaltyText}
            onChange={(e) => {
              setPenaltyText(e.target.value);
            }}
          />
        </>
      ),
    },
    {
      label: "警察担当者の名前を記入してください",
      description: <><CommonTextField value={policeManagerName} onChange={(e)=>{setPoliceManagerName(e.target.value)}}/></>,
    },
    {
      label: "担当者の電話番号を記入してください。",
      description: <><CommonTextField value={policePlacePhoneNumber} onChange={(e)=>{setPolicePlacePhoneNumber(e.target.value)}}/></>,
    },
    {
      label:
        "受け取り場所を記入してください。",
      description: <>
      受け取り場所が警察署の場合、警察署の名前を記入してください。
      <CommonTextField value={pickUpPlaceName} placeholder={"XXX警察署"} onChange={(e)=>{setPickUpPlaceName(e.target.value)}}/></>,
    },
    {
      label:
        "オーナーに電話してください。",
      description: <>
      <a href={`tel${car.ownerTel}`}><CommonButton>オーナーに電話をする。</CommonButton></a>
      <br/>
      電話が終わり次第完了ボタンを押してください。
      <CommonButton onClick={()=>setFinish(true)}>完了</CommonButton>
      </>,
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const reportBadNews = () => {
    let accidentAt = dayjs(penaltyDate+" "+penaltyTime).format("YYYY-MM-DDTHH:mm")
    ;(async ()=>{
      await api("POST usages/bad-news/",{
        reservation:reservationId,
        type:"penalty",
        accidentAt:accidentAt,
        contentDetail:penaltyText,
        placeDetail:pickUpPlaceName,
        managerName:policeManagerName,
        managerPhoneNumber:policePlacePhoneNumber
      }).then
      ((res)=>{
          console.log(res)
          // setData(res.data.results)
          // setActiveStep(activeStep+1)
          handleNext()
      }).catch(
          (err)=>{console.log(err)}
      )
  })()
  };
  if(finish){
    return <Navigate replace to="/"/>
  }
  return (
    <>
      {/* <h2>違反などにより返却不能な場合</h2> */}

      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  {!(index === steps.length - 1) && !(index === steps.length - 2)&& (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "完了" : "次へ"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </>
                  )}
                  {(index === steps.length - 2) && (
                    <>
                    {/* ここでDBにpostする処理を行う。 */}
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        onClick={reportBadNews}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        次へ
                      </Button>
                    </>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const CommonButton = styled(_CommonButton)`
  margin-bottom: 10px; ;
`;

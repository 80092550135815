import { useState, useReducer,Dispatch } from "react";
import { CommonButton as _CommonButton} from "../utils/Button";
import { TakePhoto } from "./TakePhoto";
import styled from "styled-components";
import * as color from "../utils/color";
import {
    Link,
  } from "react-router-dom";
import {CameraImage}  from "../utils/common";
import { UseCarAction,UseCarActionType,UseCarStateType } from "../reducer/useCarReducer";
import { ImageURL } from "../setting";
import { ReqAndRes } from "../api";
import { useEffect } from "react";

type InitialStateType = {
    page?: number;
    images?: string[];
  //   アクションの引数として入れたい(分離してもいいかも)
  image?:string;
  }
  
  
  const InitialState: InitialStateType = {
    page: 1,
    images: ["", "", "", "", "", "", "", ""],
  };
  
  // type Action = InitialStateType & { type: string };
  type Action = InitialStateType & { type: string };
  
  
  const reducer: React.Reducer<InitialStateType, Action> = (state, action) => {
    switch (action.type) {
      case "page": {
        return {
          ...state,
          page: action.page,
        };
      }
      case "setImage": {
          let tmp = state.images!
          tmp[state.page!-1]=action.image!
          console.log(tmp)
        return {
          ...state,
          images: tmp,
        };
      }
      default:
        return InitialState;
    }
  };
  
  export const CheckCarBodys = ({onClickNext,nextLink="",useCardispatch,useCarState,car}:{  onClickNext?:()=>void,
      nextLink?:string,useCardispatch?:Dispatch<UseCarAction>,useCarState?:UseCarStateType,car?:ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"]}) => {

    const carBodyOtehonImages = [
      car!.frontImage,
      car!.leftImage,
      car!.rightImage,
      car!.backImage,
      car!.frontLeftImage,
      car!.frontRightImage,
      car!.backLeftImage,
      car!.backRightImage
    ];

    const CarPictureActionArray = [
      UseCarActionType.ACTION_INPUT_FRONT,
      UseCarActionType.ACTION_INPUT_LEFT,
      UseCarActionType.ACTION_INPUT_RIGHT,
      UseCarActionType.ACTION_INPUT_BACK,
      UseCarActionType.ACTION_INPUT_FRONT_LEFT,
      UseCarActionType.ACTION_INPUT_FRONT_RIGHT,
      UseCarActionType.ACTION_INPUT_BACK_LEFT,
      UseCarActionType.ACTION_INPUT_BACK_RIGHT
    ];

    const carImageArray = [
      useCarState!.frontImage!.value,
      useCarState!.leftImage!.value,
      useCarState!.rightImage!.value,
      useCarState!.backImage!.value,
      useCarState!.frontLeftImage!.value,
      useCarState!.frontRightImage!.value,
      useCarState!.backLeftImage!.value,
      useCarState!.backRightImage!.value,
    ];
    
  
    const partNameArray = [
      "前面",
      "左面",
      "右面",
      "後面",
      "前左",
      "前右",
      "後左",
      "後右",
    ]; // 何枚目か。
    const [state, dispatch] = useReducer(reducer, InitialState);
    return (
      <>
        {/* dispatch({type:"setImage", image:v})  */}
        <CheckCarBody
          imageNumber={state.page!}
          partName={partNameArray[state.page!-1]}
          // image={carImageArray[state.page! - 1]}
          setImageS3Path={(v: string) => {useCardispatch!({type:CarPictureActionArray[state.page! - 1],payload: {inputValue:v}})}}
          carBodyOtehonImage={carBodyOtehonImages[state.page! - 1]}
        />
        {state.page! < 8 ? <CommonButton onClick={()=>{dispatch({type:"page" , page:state.page!+1})}} width="150px" disabled={!carImageArray[state.page! - 1]}>次へ</CommonButton> : <CommonButton onClick={()=>onClickNext!()} disabled={!carImageArray[state.page! - 1]}>外観チェックを行う</CommonButton>}
        {state.page! > 1 && <CommonButton onClick={()=>{dispatch({type:"page" , page:state.page!-1})}} width="150px">戻る</CommonButton>}
      </>
    );
  };
  
  const CheckCarBody = ({
    imageNumber,
    partName,
    setImageS3Path,
    carBodyOtehonImage,
  }: {
    imageNumber: number;
    partName: string;
    setImageS3Path?: (v: any) => void;
    carBodyOtehonImage: string;
  
  }) => {
    const [cameraMode, setCameraMode] = useState(false);
    const [image,setImage] = useState()
    useEffect(
      ()=>{
        setImage(undefined)
      },[carBodyOtehonImage]
    )
  
    return (
      <>
        <div>
          {imageNumber}枚目/8枚目 {partName}
        </div>
        <ImageItem src={ImageURL+carBodyOtehonImage} alt=""/>
        <div>上の写真のように撮影してください。</div>
        <CommonButton onClick={()=>{setCameraMode(true)}} width="150px">撮影ボタン</CommonButton>
        {cameraMode && (
          <TakePhoto
            image={image}
            setImage={setImage}
            setImageS3path={setImageS3Path!}
            setCameraMode={setCameraMode}
          />
        )}
        {!!image ? <CameraImage src={image} alt="部位１"/> : <></>}
      </>
    );
  };
  

  const CommonButton = styled(_CommonButton)`
  margin-bottom:10px;
  `

const ImageItem = styled.img`
width:300px;
margin-left:10px;
`
import styled from "styled-components";
import { Row, RowContent, RowTitle, Container } from "../utils/common";
import { CommonButton as _CommonButton} from "../utils/Button";
import { carType, usageCarInfoType } from "../utils/CommonType";
import * as color from "../utils/color";
import { useEffect,useState } from "react";
import { api } from "../api";

// 楽するために同じ画面をいろいろなところで使っているので複雑になっている。より読みやすくするための工夫はありそう。
export const Confirm = ({
  car,
  usageCarInfo,
  back,
  isLate = false,
  onClick
}: {
  car?: carType;
  usageCarInfo?: usageCarInfoType;
  isLate?: boolean;
  back?: () => void;
  onClick?:()=>void
}) => {
  // const MyInfo = {
  //   remainigPoint: 100,
  // };
  const [lastPoint,setLastPoint] = useState<number>(0)

  const consumedPoint = isLate ? car!.needPoint! * 2 : car!.needPoint!;
  const remainigPointAfterUsing = lastPoint - consumedPoint;

    // 現在のポイントを取得
    useEffect(
      ()=>{
        (async ()=>{
          await api("GET points/me/",null).then(
            (res)=>{
              setLastPoint(res.data.lastPoint)
            }
          ).catch(
            (err)=>{
              // 処理
            }
          )
        })()
      },[]
    )

  return (
      <ConfirmWrapper>
      <Row>
        <RowTitle>車両名</RowTitle>
        <RowContent>{car!.name}</RowContent>
      </Row>
      <Row>
        <RowTitle>場所</RowTitle>
        <RowContent>
          {car!.parkingAddress}<br/>
          {/* {car!.address} */}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>利用開始予定日時</RowTitle>
        <RowContent>{usageCarInfo!.start.format('YYYY-MM-DD(ddd) HH:mm')}</RowContent>
      </Row>
      <Row>
        <RowTitle>利用終了予定日時</RowTitle>
        <RowContent>{usageCarInfo!.end.format('YYYY-MM-DD(ddd) HH:mm')}</RowContent>
      </Row>
      <Row>
        <RowTitle>利用時間</RowTitle>
        <RowContent>{usageCarInfo!.utillzationTime}時間</RowContent>
      </Row>
      <Row>
        <RowTitle>利用前ポイント</RowTitle>
        <RowContent>{lastPoint}ポイント</RowContent>
      </Row>
      <Row>
        <RowTitle>消費ポイント</RowTitle>
        <RowContent>
          {consumedPoint}
          {/* {isLate && <>ペナルティにより２倍</>} */}
        </RowContent>
      </Row>
      <Row>
        <RowTitle>利用後ポイント</RowTitle>
        <RowContent>
          {remainigPointAfterUsing >= 0 ? (
            <>{remainigPointAfterUsing}</>
          ) : (
            <ErrorMessage>
              {/* <span>{remainigPointAfterUsing}</span> */}
              ポイントが不足しているので予約できません。
            </ErrorMessage>
          )}
        </RowContent>
      </Row>
      <CommonButton disabled={remainigPointAfterUsing < 0} onClick={()=>onClick!()}>
        予約を確定する
      </CommonButton>
      <CommonButton onClick={() => back!()}>戻る</CommonButton>
      </ConfirmWrapper>
  );
};

const ErrorMessage = styled.div`
  color: ${color.Red};
`

const CommonButton = styled(_CommonButton)`
margin-bottom:10px ;
`

const ConfirmWrapper = styled.div`
width:100%;
border: solid 2px ${color.Gray2} ;
border-radius:5px;
`
import { Reducer } from "redux";
import produce from "immer";

export type reduxStateType = {
  isLogin:boolean|undefined
};

const initialState: reduxStateType = {
  isLogin:false
};

export type Action =
   {
      type: "Login.SetLogin";
    }
    | {
      type: "Login.SetLogout";
    }

export const reduxReducer: Reducer<reduxStateType, Action> = produce(
  (draft: reduxStateType, action: Action) => {
    switch (action.type) {
      
      case"Login.SetLogin":{
        draft.isLogin = true
        return
      }
      case"Login.SetLogout":{
        draft.isLogin = false
        return
      }

      default: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const _: never = action;
      }
    }
  },
  initialState
);

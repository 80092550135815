import styled from "styled-components";
import * as color from "../../utils/color";
import {  useReducer,useState,useMemo} from "react";
import { CarLicense } from "./CarLicense";
import { SelectPlan } from "./SelectPlan";
import { BasicInfomation } from "./BasicInfomation";
import { RegisterView } from "./RegisterView";
import * as React from "react";
import { Row,Container } from "../../utils/common";
import { CommonButton } from "../../utils/Button";
import {
  Link,
  Navigate
} from "react-router-dom";
// import React from "react";
// todo validation
// 命名をかえる。
import { basicInfomationReducer,basicInfomationInitialState,BasicInfomationInitialStateType, CarLicenseStateType } from "../../reducer/registerUserReducer";
import { carLisenceReducer,CarLicenseInitialStateValue } from "../../reducer/registerUserReducer";
import { PlanReducer,PlanInitialState} from "../../reducer/registerUserReducer";
import { api } from "../../api";

// バリデーションを後から入れる

export const Register = () => {
  // TODO 戻る押したときの挙動をどこかできちんと書く
  const [basicInfomationState, basicInfomationDispatch] = useReducer(basicInfomationReducer, basicInfomationInitialState);
  const [carLicenseState, carLicenseDispatch] = useReducer(carLisenceReducer, CarLicenseInitialStateValue);
  // const [planState,planDispatch]= useReducer(PlanReducer,PlanInitialState)
  const [tab,setTab] = useState(1)
  const queryParameters = new URLSearchParams(window.location.search)
  const owner = queryParameters.get("owner")

  let checkSumBasicInfo = 0;
  // 無理やり。。フォーム似全て入っている状態
  (Object.keys(basicInfomationState) as (keyof BasicInfomationInitialStateType)[]).forEach(
    (v) => {
      if(!!basicInfomationState[v].value) {checkSumBasicInfo=checkSumBasicInfo+1}
    }
  )
  let checkValidationSum = 0;
  (Object.keys(basicInfomationState) as (keyof BasicInfomationInitialStateType)[]).forEach(
    (v) => {
      if(basicInfomationState[v].validation.length>0) {checkValidationSum=checkValidationSum+1}
    }
  )

  // 車のライセンス周りがきちんとうまっているか。
  let checkCarLisence = 0;
  (Object.keys(carLicenseState) as (keyof CarLicenseStateType)[]).forEach(
    (v) => {
      if(!!carLicenseState[v].value) {checkCarLisence=checkCarLisence+1}
    }
  )
  let checkCarLisenceValidationSum = 0;
  (Object.keys(carLicenseState) as (keyof CarLicenseStateType)[]).forEach(
    (v) => {
      if(carLicenseState[v].validation.length>0) {checkCarLisenceValidationSum=checkCarLisenceValidationSum+1}
    }
  )

  const BasicInfomationMemo = useMemo(
    ()=>{return <BasicInfomation dispatch={basicInfomationDispatch} state={basicInfomationState} />}
    ,[basicInfomationDispatch,basicInfomationState])

    const CarLicenseMemo = useMemo(
      ()=>{return <CarLicense dispatch={carLicenseDispatch} state={carLicenseState}/>}
      ,[carLicenseDispatch,carLicenseState])
  // const SelectPlanMemo  = useMemo(
  //   ()=>{return <SelectPlan dispatch={planDispatch} state={planState}/>}
  //   ,[planDispatch,planState])

  // const executeVaridation = async () => {
  //   dispatch({ type: "validation" });
  //   console.log(state);
  // };
  const postRegister= ()=>{
    (async ()=>{
      let data ={
        lastName:basicInfomationState.lastName.value!,
      firstName: basicInfomationState.firstName.value!,
      lastNameKana: basicInfomationState.lastNameKana.value!,
      firstNameKana: basicInfomationState.firstNameKana.value!,
      dateOfBirth: basicInfomationState.birthOfDate.value!,
      prefecture: basicInfomationState.prefecture.value!,
      address1: basicInfomationState.sikutyoson.value!,
      address2: basicInfomationState.address.value!,
      // address3: basicInfomationState.sikutyoson.value,
      phoneNumber: basicInfomationState.phoneNumber.value!,
      hasAccidentHistory: Number(basicInfomationState.hasAccidentHistory.value!),
      hasPenaltyHistory: Number(basicInfomationState.hasPenaltyHistory.value!),
      licenseImage: carLicenseState.carLicenseImageS3Path.value!,
      licenseValidDate: carLicenseState.licenseYear.value+"/"+carLicenseState.licenseMonth.value!+"/"+carLicenseState.licenseDay.value!,
      // plan: planState.plan.value!,
      mail: basicInfomationState.mailAddress.value!,
      password: basicInfomationState.password1.value!,
      owner: owner!,
      }
      console.log(CarLicenseInitialStateValue.licenseYear.value!)
      api("POST users/examination_users/",data).then(
        (res)=>{
          console.log("成功")
          setTab(5)
        }
      ).catch(
        (err)=>{console.log(err)
          
        }
      )
    })()
  }

  const validationBasicInfo = ()=>{
    // basicInfomationDispatch({type:BasicInfomationActionType.ACTION_CHECK_BASIC_INFOMATION,payload:null});
    setTab(2)
    return 1
  }

  if(tab===1){
    return <Container>
      {BasicInfomationMemo}
      <CommonButton onClick={validationBasicInfo} disabled={(checkSumBasicInfo!==12)||(checkValidationSum!==0)}>次へ</CommonButton>
      {/* <CommonButton onClick={()=>console.log(checkSumBasicInfo)} >テスト</CommonButton> */}
    </Container>
  }
  if(tab===2){
    return <Container>
      {CarLicenseMemo}
      <CommonButton onClick={() =>setTab(1)} marginBottom="10px;">
              戻る
            </CommonButton>
      <CommonButton onClick={()=>{setTab(3)}} disabled={(checkCarLisence!==6)||(checkValidationSum!==0)}>次へ</CommonButton>
      {/* <CommonButton onClick={()=>{console.log(checkCarLisence);console.log(checkCarLisenceValidationSum)}} >test</CommonButton> */}

    </Container>
  }
  // if(tab===3){
  //   return <Container>
  //     {SelectPlanMemo}
  //     <CommonButton onClick={() =>setTab(2)} marginBottom="10px;">
  //             戻る
  //           </CommonButton>
  //     <CommonButton onClick={()=>{setTab(4)}}>次へ</CommonButton>
  //     {/* <CommonButton onClick={()=>{console.log(checkCarLisence);console.log(checkCarLisenceValidationSum)}} >test</CommonButton> */}
  //   </Container>
  // }
  if(tab===3){
    return <Container>
      <Title>確認画面</Title>
          <RegisterView basicInfomationState={basicInfomationState} carLicenseState={carLicenseState} owner={owner!}/>
          <Row>
          <CommonButton onClick={() =>setTab(2)} marginBottom="10px;">
              戻る
            </CommonButton>
            {/* <CommonButton onClick={postRegister}><Link to="finish">登録する</Link></CommonButton> */}
            <CommonButton onClick={postRegister}>登録する</CommonButton>


          </Row>
    </Container>
  }

  if(tab===5){
    return <Navigate replace to="finish"/>
  }

  return <>error</>

};

// {}でかかないと、オブジェクトとして認識されてしまうという問題がある。
const ErrorMessages = ({errors}:{errors?:any[]|any}) => {
  if (errors.length > 0) {
    // console.log("ヒット")
    return (<>{errors.map((v: string,index:number) => <p key={index} style={{color:"red"}}>{v}</p>)}</>);
  } else {
    return <></>;
  }
};

//   ここにはロゴが入る。確認用のため
const Title = styled.div`
  width: 80%;
  font-size: 14px;
  text-align: center;
`;

const LogoTitle = styled.div`
font-weight:1500;
color:${color.Blue2};
font-size:18px;
text-align:center ;
margin-bottom:10px;
`
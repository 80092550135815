import {  useState } from "react";
import { Row,  Container } from "../utils/common";
import { CommonButton,AgreeButton } from "../utils/Button";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { SingleCheckBox } from "../utils/CheckBox";
import * as color from "../utils/color";

export const CheckBeforeUsage = ({
    nextPath = "",
    onClick,
  }: {
    nextPath?: string;
    onClick?: () => void;
  }) => {
    const [checks, setChecks] = useState({
      check1: false,
      check2: false,
      check3: false,
      check4: false,
    });
    return (
      <Container>
        <Row>以下をすべてチェックをしないと先へ進めません。</Row>
        <Row>
          <CheckWrapper>
            <CheckItem>
              会員登録から現在までに交通違反などで免許停止となっていないか
            </CheckItem>
            <CheckBoxField>
              <SingleCheckBox
                // checked={checks.check1}
                value={checks.check1}
                onChange={() => setChecks({ ...checks, check1: !checks.check1 })}
              />
            </CheckBoxField>
          </CheckWrapper>
        </Row>
        <Row>
          <CheckWrapper>
          <CheckItem>
            会員登録から現在までに(本サービス以外も含め)運転する車両で事故をおこしていない{" "}
          </CheckItem>
          <CheckBoxField>
            <SingleCheckBox
              value={checks.check2}
              onChange={() => setChecks({ ...checks, check2: !checks.check2 })}
            />
          </CheckBoxField>
          </CheckWrapper>
        </Row>
        <Row>
          <CheckWrapper>
          <CheckItem>免許書記載事項に変更がない</CheckItem>
          <CheckBoxField>
            <SingleCheckBox
              // label={"免許書記載事項に変更がない"}
              value={checks.check3}
              onChange={() => setChecks({ ...checks, check3: !checks.check3 })}
            />
          </CheckBoxField>
          </CheckWrapper>
        </Row>
        <Row>
        <CheckWrapper>
          <CheckItem>
            規約に同意する。（規約の閲覧はこちらをクリックしてください■）
          </CheckItem>
          <CheckBoxField>
            <SingleCheckBox
              value={checks.check4}
              onChange={() => setChecks({ ...checks, check4: !checks.check4 })}
            />
          </CheckBoxField>
          </CheckWrapper>
        </Row>

      <ButtonGroup>
      <></>
      </ButtonGroup>

        <Link to={nextPath}>
          <AgreeButton
            disabled={
              !(checks.check1 && checks.check2 && checks.check3 && checks.check4)
            }
            onClick={() => {
              onClick!();
            }}
          >
            次へ
          </AgreeButton>
        </Link>
      </Container>
    );
  };
  
  const ButtonGroup = styled.div`
  display:flex;
  justify-content:space-between ;
  width:100%;

  `

const CheckWrapper = styled.div`
display:flex;
align-items:center ;
width:100% ;
`
  
  const CheckItem = styled.div`
    /* border: solid 1px; */
    padding:10px;
    width: 100%;
    border-radius:5px;
    border:solid 2px ${color.Gray2};
    margin-right:10px; ;
    /* margin:auto; */
    
  `;
  
  const CheckBoxField = styled.div`
  width:50px
  `;
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createGlobalStyle } from "styled-components";
import * as color from "./utils/color";
import { Provider } from "react-redux";
import { legacy_createStore, compose } from "redux";
import { reduxReducer } from "./reduxReducer";

interface ExtendedWindow extends Window {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
}
declare let window: ExtendedWindow;

export const GlobalStyle = createGlobalStyle`
 html, body, #root {
   height: 100%;
 }
 
 body {
   /* https://css-tricks.com/snippets/css/system-font-stack/ */
   font-size: 12px;
   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
     Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
 /* 文字列の自動改行 */
   overflow-wrap: break-word;
  width:100vw;
  height:100vh;
  color:${color.Black};
  /* background-color:${color.Blue1}; */
 }
 a {
  text-decoration: none;
  color:${color.Black};
  /* width:100%; */
 }
 /* https://yumanoblog.com/safari-input-reset/ */
  select {
  -webkit-appearance: none;
  appearance: none;
}
 `;

const store = legacy_createStore(reduxReducer,
  undefined,
   process.env.NODE_ENV === 'development'
     ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.()
     : undefined,
  )


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
    <Provider store={store}>
    <GlobalStyle />
    <App />
    </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

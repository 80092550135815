import {
  inputStringType,
  inputNumberType,
  inputBooleanType,
  initailStringInput,
  initailNumberInput,
  initailBooleanInput,
} from "./reducerCommon";

// 車の利用の際のreducerを作成する。
export type UseCarStateType = {
  carLicenseImage: inputStringType;
  // 前
  frontImage?: inputStringType;
  // 左
  leftImage?: inputStringType;
  // 右
  rightImage?: inputStringType;
  // 後ろ
  backImage?: inputStringType;
  // 前左
  frontLeftImage?: inputStringType;
  // 前右
  frontRightImage?: inputStringType;
  // 後ろ左
  backLeftImage?: inputStringType;
  // 後ろ右
  backRightImage?: inputStringType;
  // 傷報告の有無
  hasDamageReport?: inputBooleanType;
  // 傷画像
  damageReportImage?: inputStringType;
  //   傷テキスト
  damageReportText?: inputStringType;
};

export enum UseCarActionType {
  ACTION_INPUT_CAR_LICENSE_IMAGE = "ACTION_INPUT_CAR_LICENSE_IMAGE",
  ACTION_INPUT_FRONT = "ACTION_INPUT_FRONT",
  ACTION_INPUT_LEFT = "ACTION_INPUT_LEFT",
  ACTION_INPUT_RIGHT = "ACTION_INPUT_RIGHT",
  ACTION_INPUT_BACK = "ACTION_INPUT_BACK",
  ACTION_INPUT_FRONT_LEFT = "ACTION_INPUT_FRONT_LEFT",
  ACTION_INPUT_FRONT_RIGHT = "ACTION_INPUT_FRONT_RIGHT",
  ACTION_INPUT_BACK_LEFT = "ACTION_INPUT_BACK_LEFT",
  ACTION_INPUT_BACK_RIGHT = "ACTION_INPUT_BACK_RIGHT",
  ACTION_INPUT_HAS_DAMAGE_REPORT = "ACTION_INPUT_HAS_DAMAGE_REPORT",
  ACTION_INPUT_DAMAGE_REPORT_TEXT = "ACTION_INPUT_DAMAGE_REPORT_TEXT",
  ACTION_INPUT_DAMAGE_REPORT_IMAGE = "ACTION_INPUT_DAMAGE_REPORT_IMAGE",
}

export type UseCarPayloadType = {
  inputValue?: string;
  inputBooleanValue?: boolean;
};

export type UseCarAction = {
  type: UseCarActionType;
  payload: UseCarPayloadType;
};

// 初期値
export const initialUseCarState: UseCarStateType = {
  carLicenseImage: initailStringInput,
  // 前
  frontImage: initailStringInput,
  // 左
  leftImage: initailStringInput,
  // 右
  rightImage: initailStringInput,
  // 後ろ
  backImage: initailStringInput,
  // 前左
  frontLeftImage: initailStringInput,
  // 前右
  frontRightImage: initailStringInput,
  // 後ろ左
  backLeftImage: initailStringInput,
  // 後ろ右
  backRightImage: initailStringInput,
  // 傷報告の有無
  hasDamageReport: initailBooleanInput,
  // 傷画像
  damageReportImage: initailStringInput,
  //   傷テキスト
  damageReportText: initailStringInput,
};

export const UseCarReducer: React.Reducer<UseCarStateType, UseCarAction> = (
  state,
  action
) => {
  switch (action.type) {
    case UseCarActionType.ACTION_INPUT_CAR_LICENSE_IMAGE: {
      return {
        ...state,
        carLicenseImage: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case UseCarActionType.ACTION_INPUT_FRONT: {
      return {
        ...state,
        frontImage: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case UseCarActionType.ACTION_INPUT_LEFT: {
      return {
        ...state,
        leftImage: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case UseCarActionType.ACTION_INPUT_RIGHT: {
      return {
        ...state,
        rightImage: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case UseCarActionType.ACTION_INPUT_BACK: {
      return {
        ...state,
        backImage: { value: action.payload.inputValue!, validation: [] },
      };
    }

    case UseCarActionType.ACTION_INPUT_FRONT_LEFT: {
      return {
        ...state,
        frontLeftImage: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case UseCarActionType.ACTION_INPUT_FRONT_RIGHT: {
      return {
        ...state,
        frontRightImage: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case UseCarActionType.ACTION_INPUT_BACK_LEFT: {
      return {
        ...state,
        backLeftImage: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case UseCarActionType.ACTION_INPUT_BACK_RIGHT: {
      return {
        ...state,
        backRightImage: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case UseCarActionType.ACTION_INPUT_DAMAGE_REPORT_IMAGE: {
      return {
        ...state,
        damageReportImage: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }

    case UseCarActionType.ACTION_INPUT_DAMAGE_REPORT_TEXT: {
      return {
        ...state,
        damageReportText: {
          value: action.payload.inputValue!,
          validation: [],
        },
      };
    }
    case UseCarActionType.ACTION_INPUT_HAS_DAMAGE_REPORT: {
      return {
        ...state,
        hasDamageReport: {
          value: action.payload.inputBooleanValue!,
          validation: [],
        },
      };
    }
    

    default:
      return initialUseCarState;
  }
};

import React from "react";
import { Document, Page } from "react-pdf";
import { ImageURL } from "../setting";
export const ServiceInfomation = () => {
  console.log(ImageURL + "car-share.pdf")
  return (
    <>
      <div>
        <Document file={ImageURL+"car-share.pdf"}>
          <Page pageNumber={1} />
        </Document>
      </div>
    </>
  );
};

import styled from "styled-components";
import * as color from "../../utils/color";
import * as React from "react";
import { Row, RowContent, RowTitle } from "../../utils/common";
import { CommonTextField } from "../../utils/TextField";
import { RadioGroup } from "../../utils/Radio";
import {BasicInfomationActionType} from "../../reducer/registerUserReducer"

type Props = {
  state?:any;
  dispatch: any;
};

// registerのdispatchとみつに結びついたコンポーネントとなっている。
export const BasicInfomation = React.memo(({ dispatch,state }: Props) => {
  // const [loading, setLoading] = useState(false);
  // ログイン関数
  // ローカルストレージに保存する。

  return (
    <Container>
      <Row>
        <RowTitle>姓</RowTitle>
          {state.firstName.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
          
        <RowContent>
          <CommonTextField
          value={state.firstName.value}
            onChange={(e) =>
              dispatch({ type: BasicInfomationActionType.ACTION_INPUT_FIRST_NAME, payload: {inputValue:e.currentTarget.value}})
            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>名</RowTitle>
        {state.lastName.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.lastName.value}
            onChange={(e) =>
              dispatch({ type: "ACTION_INPUT_LAST_NAME", payload: {inputValue:e.currentTarget.value}})
            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>姓（ふりがな）</RowTitle>
        {state.firstNameKana.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          {
            <CommonTextField
            value={state.firstNameKana.value}
              onChange={(e) =>
                dispatch({ type: BasicInfomationActionType.ACTION_INPUT_FIRST_NAME_KANA, payload: {inputValue:e.currentTarget.value}})

              }
            />
          }
        </RowContent>
      </Row>
      <Row>
        <RowTitle>名（ふりがな）</RowTitle>
        {state.lastNameKana.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.lastNameKana.value}
            onChange={(e) =>
              dispatch({ type: BasicInfomationActionType.ACTION_INPUT_LAST_NAME_KANA, payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>
          生年月日(例19900101)
        </RowTitle>
        {state.birthOfDate.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.birthOfDate.value}
            onChange={(e) =>
              dispatch({ type:BasicInfomationActionType.ACTION_INPUT_BIRTH_OF_DATE, payload: {inputValue:e.currentTarget.value}})
              
            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>都道府県</RowTitle>
        {state.prefecture.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.prefecture.value}

            onChange={(e) =>
              dispatch({ type: "ACTION_INPUT_PREFECTURE", payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>市区町村</RowTitle>
        {state.sikutyoson.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.sikutyoson.value}

            onChange={(e) =>
              dispatch({ type: "ACTION_INPUT_SIKUTYOSON", payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>上記以外の住所</RowTitle>
        {state.address.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.address.value}

            onChange={(e) =>
              dispatch({ type: "ACTION_INPUT_ADDRESS", payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>携帯電話番号</RowTitle>
        {state.phoneNumber.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.phoneNumber.value}

            onChange={(e) =>
              dispatch({ type: "ACTION_INPUT_PHONE_NUMBER", payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>

      <Row>
        <RowTitle>メールアドレス</RowTitle>
        {state.mailAddress.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.mailAddress.value}

            onChange={(e) =>
              dispatch({ type: BasicInfomationActionType.ACTION_INPUT_MAIL_ADDRESS, payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>パスワード(必ず覚えておいてください。)</RowTitle>
        {state.password1.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.password1.value}
          type="password"
            onChange={(e) =>
              dispatch({ type: BasicInfomationActionType.ACTION_INPUT_PASSWORD1, payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>パスワード確認</RowTitle>
        {state.password2.validation.map((v:any,index:number)=>{
            return <AttentionWithRed key={index}>{v}</AttentionWithRed>
          })}
        <RowContent>
          <CommonTextField
          value={state.password2.value}
          type="password"
            onChange={(e) =>
              dispatch({ type: BasicInfomationActionType.ACTION_INPUT_PASSWORD2, payload: {inputValue:e.currentTarget.value}})

            }
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>違反歴</RowTitle>
        <RowContent>
          <RadioGroup
          defaultValue={String(state.hasPenaltyHistory.value!)}
            radioProps={[
              { value: "0", label: "なし" },
              { value: "1", label: "あり" },
            ]}
            onChange={(e) => {
              console.log(e.currentTarget.value)
              dispatch({ type: "ACTION_INPUT_HAS_PENALTY_HISTORY", payload: {inputValue:Number(e.currentTarget.value)}})

            }}
          />
        </RowContent>
      </Row>
      <Row>
        <RowTitle>事故歴の有無</RowTitle>
        <RowContent>
        <RadioGroup
            radioProps={[
              { value: "0", label: "なし" },
              { value: "1", label: "あり" },
            ]}
            defaultValue={String(state.hasAccidentHistory.value!)}
            onChange={(e) => {
              dispatch({ type: "ACTION_INPUT_HAS_ACCIDENT_HISTORY", payload: {inputValue:Number(e.currentTarget.value)}})
            }}
          />
        </RowContent>
      </Row>
      
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const AttentionWithRed = styled.div`
color:${color.Red}
`
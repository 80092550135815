import { useId, useState } from "react";
import styled from "styled-components";

export const Radio = ({
  name,
  value,
  label,
  checked,
  onChange,
}: {
  name?: string;
  value?: string;
  label?: string;
  checked?: boolean;
  onChange?: (v: any) => void;
}) => {
  return (
    <div>
      <RadioLabel>
        <RadioCustom
          type="radio"
          name={name!}
          value={value}
          checked={checked}
          onChange={onChange}
        />
        {label}
      </RadioLabel>
    </div>
  );
};

type radioPropType = {
  value: string;
  label: string;
};

export const RadioGroup = ({
  radioProps,
  onChange,
  isVertical,
  defaultValue,
}: {
  radioProps: radioPropType[];
  onChange?: (e: any) => void;
  isVertical?: boolean;
  defaultValue?: string;
}) => {
  const Radioid = useId();
  const [check, setCheck] = useState<String>(defaultValue!);
  return (
    <RadioWrapper isVertical={isVertical}>
      {radioProps.map((radioProp: radioPropType, index) => {
        return (
          <Radio
            key={index}
            name={Radioid}
            value={radioProp.value}
            label={radioProp.label}
            onChange={(e) => {
              setCheck(e.target.value);
              // 追加で処理をしたい場合。
              onChange!(e);
            }}
            checked={check === radioProp.value}
          />
        );
      })}
    </RadioWrapper>
  );
};

const RadioWrapper = styled.div<{ isVertical?: boolean }>`
  display: flex;
  /* flex-direction:column; */
  ${({ isVertical }) => (isVertical ? "flex-direction:column;" : ";")}
`;
const RadioCustom = styled.input`
  /* display:flex */
`;

const RadioLabel = styled.label`
  /* font-size: 24px; ; */
`;

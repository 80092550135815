import { Container } from "../../utils/common";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { CheckBeforeUsage } from "../../components/CheckBeforeUsage";
import { CarSchedule } from "./CarSchedule";
import { CarLists } from "./CarLists";
import { usageCarInfoType } from "../../utils/CommonType";

import { Confirm } from "../../components/Confirm";
import { api, ReqAndRes } from "../../api";
import { Navigate } from "react-router-dom";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

export const NewRental = () => {
  const [mode, setMode] = useState(1);
  const [selectedCar, setSelectedCar] = useState<
    | ReqAndRes["GET cars/for_residence_user/"]["res"]["data"]["results"][0]
    | undefined
  >();
  const [usageCarInfo, setUsageCarInfo] = useState<
    usageCarInfoType | undefined
  >();
  // const
  const [carList, setCarList] = useState<
    ReqAndRes["GET cars/for_residence_user/"]["res"]["data"]["results"]
  >([]);

  const [notAvailableSpanArray,setNotAvailableSpanArray] = useState<ReqAndRes["GET cars/schedule/"]["res"]["data"]>([])

  // 車両一覧を取得
  useEffect(() => {
    (async () => {
      await api("GET cars/for_residence_user/", null)
        .then((res) => {
          setCarList(res.data.results);
        })
        .catch((err) => {
          // 処理
          setMode(-1)
        });
    })();
  }, []);
  // 車の予定を取得
  useEffect(() => {
    if (!selectedCar) {
      // 車が選択されていないので発火しない。
    } else {
      // 車が選択されている時だけ発火する。
      (async () => {
        await api("GET cars/schedule/", { id: selectedCar!.id })
          .then((res) => {
            console.log(res.data)
            setNotAvailableSpanArray(res.data)
          })
          .catch((err) => {
            // 処理
          });
      })();
    }
  }, [selectedCar]);

  const rental = () => {
    (async () => {
      await api("POST usages/reservations/", {
        car: selectedCar!.id,
        useTime: usageCarInfo!.utillzationTime,
        startReservationDateTime:
          usageCarInfo!.start.format("YYYY-MM-DD HH:mm"),
        endReservationDateTime: usageCarInfo!.end.format("YYYY-MM-DD HH:mm"),
      })
        .then((res) => {
          // 予約に成功した。リダイレクトしたい。一旦テスト
          console.log(res);
          setMode(5);
        })
        .catch((err) => {});
    })();
  };

  let Content = () => {
    return <></>;
  };
  if (mode === -1) {
    return <Container>
        お支払いを完了いないため利用ができません。
      </Container>
  } 

  if (mode === 1) {
    Content = () => {
      return <CheckBeforeUsage onClick={() => setMode(2)} />;
    };
  } else if (mode === 2) {
    Content = () => {
      return (
        <CarLists
          onClick={(car) => {
            setMode(3);
            setSelectedCar(car);
            console.log(car);
          }}
          carList={carList}
        />
      );
    };
  } else if (mode === 3) {
    Content = () => {
      return (
        <CarSchedule
          onClick={(usageCarInfo: any) => {
            setUsageCarInfo(usageCarInfo);
            setMode(4);
          }}
          isLate={false}
          notAvailableSpanArray={notAvailableSpanArray}
        />
      );
    };
  } else if (mode === 4) {
    Content = () => {
      return (
        <Confirm
          car={selectedCar}
          usageCarInfo={usageCarInfo}
          onClick={rental}
          back={() => {
            setMode(3);
          }}
        />
      );
    };
  } else if (mode === 5) {
    Content = () => {
      return <Navigate replace to="/" />;
    };
  }
  return (
    <Container>
      <Content />
    </Container>
  );
};

import { Row, RowContent, RowTitle, Container } from "../../utils/common";
import styled from "styled-components";
import * as color from "../../utils/color";
import { carType } from "../../utils/CommonType";
import BMW  from "../../sozai/carlist/BMW340_left_front_side.jpeg"
import Ferali  from "../../sozai/carlist/FIMG_8721.jpg"
import  Mini from "../../sozai/carlist/mini.jpg"
import { ReqAndRes } from "../../api";
import { ImageURL } from "../../setting";





export const CarLists = ({ onClick,carList }: { onClick?: (car: carType) => void, carList?:ReqAndRes["GET cars/for_residence_user/"]["res"]["data"]["results"] }) => {
    
    return (
      <>
        {/* 車両一覧 */}
        {carList!.map((v) => {
          return (
            <CarCardWrapper
              key={v.id}
              onClick={() => {
                onClick!(v);
              }}
            >
              <CarCard
                carName={v.name}
                manshonName={v.parkingAddress}
                carImage={v.thumbnailImage}
                // address={v.address}
                size={v.size}
                point={v.needPoint}
              />
            </CarCardWrapper>
          );
        })}
      </>
    );
  };
  
  const CarCardWrapper = styled.div`
    width:100%;
  
  `
  
  const CarCard = ({
    carName,
    manshonName,
    carImage,
    address,
    size,
    point,
  }: {
    carName?: string;
    manshonName?: string;
    carImage?: string;
    address?: string;
    size?: string;
    point?: number;
  }) => {
    return (
      <CardBox>
        {/* ハードコーディングよくない */}
        <CarImageWrapper url={ImageURL+carImage}>
          {/* ここに画像が入ります。以下がパス */}
        {/* {carImage} */}
         
        <Price><div>{point}pt/2時間</div></Price>
        </CarImageWrapper>
        <div>{carName}</div>
        <div>
          {manshonName} {address}
        </div>
        <div>{size}</div>
      </CardBox>
    );
  };

  const Price = styled.div`
  font-size:18px;
  font-weight:500 ;
  background-color:${color.Black} ;
  color:${color.White}
  `
  
  const CardBox = styled.div`
    /* width: 300px; */
    width:100%;
    border: solid 2px ${color.Gray2};
    border-radius:5px ;
    margin-bottom: 15px;
    /* background-color:${color.Gray1} ; */
    font-size:8px;
  `;
  
  const CarImageWrapper = styled.div<{url?:string}>`
    /* width:100%; */
    /* width: 300px; */
    height: 260px;
    /* border: solid 1px ${color.Gray2};
    border-radius:5px ; */
    display:flex ;
    align-items:end ;
    ${({url})=> url? `background-image: url(${url});`:`;`}
    background-size:contain ;
    background-repeat:no-repeat ;
  `;
  

  
import { Row, RowContent, RowTitle, Container } from "../../utils/common";
import { CommonButton } from "../../utils/Button";
import { useReducer, useEffect, useState } from "react";
import { CommonTextField } from "../../utils/TextField";
import { RadioGroup } from "../../utils/Radio";
import styled from "styled-components";
import * as color from "../../utils/color";
import { api } from "../../api";

// ここはあとで書き換える！！

export type InitialStateType = {
  sei?: string;
  mei?: string;
  seiKana?: string;
  meiKana?: string;
  birthDay?: string;
  prefecture?: string;
  sikutyoson?: string;
  address?: string;
  phoneNumber?: string;
  val_errors?: string[];
  // plan?: string;
};

type Action = InitialStateType & { type: string };

const initialValue = {
  sei: "",
  mei: "",
  seiKana: "",
  meiKana: "",
  birthDay: "",
  prefecture: "",
  sikutyoson: "",
  address: "",
  phoneNumber: "",
  // plan: "3",
};

const reducer: React.Reducer<InitialStateType, Action> = (state, action) => {
  switch (action.type) {
    case "sei": {
      return {
        ...state,
        sei: action.sei,
      };
    }
    case "mei": {
      // dispatchが走ったときしか反応しないが問題。validationという別のアクションをつくるのがいいのでは？！
      // if (action.mei===""){
      //   let tmp = {...state.val_errors,{mei:"空はだめ"}}

      //   return {...state,val_errors:tmp}
      // }
      return {
        ...state,
        mei: action.mei,
        // mei_val_message:""
      };
    }
    case "seiKana": {
      return {
        ...state,
        seiKana: action.seiKana,
      };
    }
    case "meiKana": {
      return {
        ...state,
        meiKana: action.meiKana,
      };
    }
    case "birthDay": {
      return {
        ...state,
        birthDay: action.birthDay,
      };
    }
    case "prefecture": {
      return {
        ...state,
        prefecture: action.prefecture,
      };
    }

    case "sikutyoson": {
      return {
        ...state,
        sikutyoson: action.sikutyoson,
      };
    }

    case "address": {
      return {
        ...state,
        address: action.address,
      };
    }

    case "phoneNumber": {
      return {
        ...state,
        phoneNumber: action.phoneNumber,
      };
    }

    // case "plan": {
    //   return {
    //     ...state,
    //     plan: action.plan,
    //   };
    // }

    case "validation": {
      let tmp = [];
      if (state.mei === "") {
        tmp.push("名称は空ではいけません");
      }

      if (state.sei === "") {
        tmp.push("姓は空ではいけません");
      }

      if (state.seiKana === "") {
        tmp.push("姓(かな)は空ではいけません");
      }

      if (state.phoneNumber === "") {
        tmp.push("電話番号はハイフン抜きで数字で入力してください");
      }

      if (state.prefecture === "") {
        tmp.push("都道府県を入力してください");
      }

      if (tmp.length === 0) {
        return {
          ...state,
          nextFlag: true,
        };
      }
      console.log("tmp");
      console.log(tmp);
      return {
        ...state,
        val_errors: tmp,
      };
    }

    case "initialize": {
      return {
        ...state,
        // plan: action.plan,
        mei: action.mei,
        sei: action.sei,
        meiKana: action.meiKana,
        seiKana: action.seiKana,
        birthDay: action.birthDay,
        prefecture: action.prefecture,
        phoneNumber: action.phoneNumber,
        sikutyoson: action.sikutyoson,
        address: action.address,
      };
    }

    default:
      return initialValue;
  }
};

export const RegisterModify = () => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const [mode, setMode] = useState(0);
  const [userId, setUserId] = useState<string>();
  const getMyInfo = async () => {
    await api("GET users/residence_users/me/", null)
      .then((res) => {
        console.log(res);
        setUserId(res.data.id);
        dispatch({
          type: "initialize",
          // plan: res.data.plan,
          mei: res.data.lastName,
          sei: res.data.firstName,
          meiKana: res.data.lastNameKana,
          seiKana: res.data.firstNameKana,
          birthDay: res.data.dateOfBirth,
          prefecture: res.data.prefecture,
          sikutyoson: res.data.address1,
          phoneNumber: res.data.phoneNumber,
          address: res.data.address2,
        });
      })
      .catch((err) => {
        setMode(-1)
        console.log(err);
      });
  };
  const changeUserInfo = () => {
    (async () => {
      await api("PATCH users/residence_users/", {
        id: userId!,
        firstName: state.sei,
        lastName: state.mei,
        lastNameKana: state.meiKana,
        firstNameKana: state.seiKana,
        dateOfBirth: state.birthDay,
        prefecture: state.prefecture,
        address1: state.sikutyoson,
        address2: state.address,
        phoneNumber: state.phoneNumber,
      })
        .then((res) => {
          getMyInfo()
          setMode(1)
          console.log(res);
          // setData(res.data.results)
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  };
  useEffect(() => {
    getMyInfo();
  }, []);
  if (mode === -1) {
    return (
      <Container>
        お支払いを完了いないため利用ができません。
      </Container>
    );
  }
  if (mode === 0) {
    return (
      <Container>
        ローディング
      </Container>
    );
  }
  if (mode === 1) {
    return (
      <Container>
        <ViewUserData state={state} />
        <CommonButton onClick={() => setMode(2)}>変更申請</CommonButton>
        <CommonButton>退会申請</CommonButton>
      </Container>
    );
  }
  if (mode === 2) {
    return (
      <Container>
        <Row>
          <RowTitle>姓</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.sei}
              onChange={(e) =>
                dispatch({ type: "sei", sei: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>名</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.mei}
              onChange={(e) =>
                dispatch({ type: "mei", mei: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>姓（ふりがな）</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.seiKana}
              onChange={(e) =>
                dispatch({ type: "seiKana", seiKana: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>名（ふりがな）</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.meiKana}
              onChange={(e) =>
                dispatch({ type: "meiKana", meiKana: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>生年月日(例19900101)</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.birthDay}
              onChange={(e) =>
                dispatch({ type: "birthDay", birthDay: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>都道府県</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.prefecture}
              onChange={(e) =>
                dispatch({
                  type: "prefecture",
                  prefecture: e.currentTarget.value,
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>市区町村</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.sikutyoson}
              onChange={(e) =>
                dispatch({
                  type: "sikutyoson",
                  sikutyoson: e.currentTarget.value,
                })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>住所</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.address}
              onChange={(e) =>
                dispatch({ type: "address", address: e.currentTarget.value })
              }
            />
          </RowContent>
        </Row>
        <Row>
          <RowTitle>携帯電話番号</RowTitle>
          <RowContent>
            <CommonTextField
              value={state.phoneNumber}
              onChange={(e) =>
                dispatch({
                  type: "phoneNumber",
                  phoneNumber: e.currentTarget.value,
                })
              }
            />
          </RowContent>
        </Row>
        {/* <Row>
            <RowTitle>プラン</RowTitle>
            <RowContent>
            <RadioGroup 
            defaultValue={state.plan}
            radioProps={
            [
              {label:"ベージック会員 １万円　20pt/月",value:"1"},
              {label:"ゴールド会員 ２万円　50pt/月",value:"2"},
              {label:"プラチナ会員 ３万円　100pt/月 優先予約権付き",value:"3"}
            ]
          }
          isVertical={true}
          onChange={(e)=>{dispatch({type:"plan",plan:e.target.value})}}
          />
            </RowContent>
          </Row> */}
        <CommonButton onClick={() => setMode(1)}>キャンセル</CommonButton>
        <CommonButton onClick={(e) => setMode(3)}>確認</CommonButton>
      </Container>
    );
  }
  if (mode === 3) {
    return (
      <Container>
        <ViewUserData state={state} />
        <CommonButton marginTop="5px;" onClick={() => setMode(2)}>
          編集に戻る
        </CommonButton>
        <CommonButton marginTop="5px;" onClick={changeUserInfo}>
          変更
        </CommonButton>
      </Container>
    );
  }
  if (mode === 4) {
    return (
      <Container>
        変更申請を完了しました。完了次第メールをお送りします。
      </Container>
    );
  }

  return <Container>error</Container>;
};

const ViewUserData = ({ state }: { state: any }) => {
  // プラン
  const planName: { [key: number | string]: string } = {
    1: "ベーシックプラン",
    2: "ゴールドプラン",
    3: "プラチナプラン",
    // 無理やり対応。どっかでなおす。
    basic: "ベーシックプラン",
    gold: "ゴールドプラン",
    platinum: "プラチナプラン",
  };
  return (
    <ConfirmWrapper>
      <Row>
        <RowTitle>姓</RowTitle>
        <RowContent>{state.sei}</RowContent>
      </Row>
      <Row>
        <RowTitle>名</RowTitle>
        <RowContent>{state.mei}</RowContent>
      </Row>
      <Row>
        <RowTitle>姓（ふりがな）</RowTitle>
        <RowContent>{state.seiKana}</RowContent>
      </Row>
      <Row>
        <RowTitle>名（ふりがな）</RowTitle>
        <RowContent>{state.meiKana}</RowContent>
      </Row>
      <Row>
        <RowTitle>生年月日</RowTitle>
        <RowContent>{state.birthDay}</RowContent>
      </Row>
      <Row>
        <RowTitle>都道府県</RowTitle>
        <RowContent>{state.prefecture}</RowContent>
      </Row>
      <Row>
        <RowTitle>市区町村</RowTitle>
        <RowContent>{state.sikutyoson}</RowContent>
      </Row>
      <Row>
        <RowTitle>住所</RowTitle>
        <RowContent>{state.address}</RowContent>
      </Row>
      <Row>
        <RowTitle>携帯電話番号</RowTitle>
        <RowContent>{state.phoneNumber}</RowContent>
      </Row>
      {/* <Row>
          <RowTitle>プラン</RowTitle>
          <RowContent>{planName[state.plan!]}</RowContent>
        </Row> */}
    </ConfirmWrapper>
  );
};

const ConfirmWrapper = styled.div`
  width: 100%;
  /* background-color:${color.Gray1} ; */
  border: solid 2px ${color.Gray2};
  border-radius: 5px;
`;

import { useState } from "react";
import { CommonButton } from "../../utils/Button";
import { SingleCheckBox } from "../../utils/CheckBox";
import { RadioGroup } from "../../utils/Radio";
import { CommonTextArea } from "../../utils/TextField";
import { api, ReqAndRes } from "../../api";
import dayjs from "dayjs";
export const NomalReturn = ({
  nextOnClick,
  reservationId,
}: {
  nextOnClick?: () => void;
  reservationId: number;
  car: ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"];
}) => {
  const [check1, setCheck1] = useState("0");
  const [check2, setCheck2] = useState("0");
  const [check3, setCheck3] = useState("0");
  const [reason, setReason] = useState("");
  const [check4, setCheck4] = useState(false);
  const viewReson =
    Boolean(Number(check1)) ||
    Boolean(Number(check2)) ||
    Boolean(Number(check3));
  

  const postBadNews = () => {
    let reasonType = ""
    if(check1){
      reasonType="事故"
    }
    if(check2){
      reasonType="違反"
    }

    if (viewReson) {
      (async () => {
        await api("POST usages/bad-news/", {
          type: "resolved",
          reservation: Number(reservationId),
          isReturn: true,
          contentDetail: reasonType+":"+reason,
          accidentAt:dayjs().format("YYYY-MM-DDTHH:mm")
        })
          .then((res) => {
            console.log(res);
            nextOnClick!();
          })
          .catch((err) => {
            console.log(err);
          });
      })();
    } else{
      nextOnClick!();
    }
  };

  return (
    <>
      <div>通常返却</div>
      <div>貸出時に事故</div>
      <RadioGroup
        radioProps={[
          { value: "1", label: "あり" },
          { value: "0", label: "なし" },
        ]}
        onChange={(e) => {
          setCheck1(e.target.value);
        }}
        defaultValue={check1}
      />
      <div>貸出時に違反・取締</div>
      <RadioGroup
        radioProps={[
          { value: "1", label: "あり" },
          { value: "0", label: "なし" },
        ]}
        onChange={(e) => {
          setCheck2(e.target.value);
        }}
        defaultValue={check2}
      />
      <div>貸出時に事故</div>
      <RadioGroup
        radioProps={[
          { value: "1", label: "あり" },
          { value: "0", label: "なし" },
        ]}
        onChange={(e) => {
          setCheck3(e.target.value);
        }}
        defaultValue={check3}
      />
      {viewReson && (
        <>
          <div>内容の報告</div>
          <CommonTextArea
            onChange={(e: any) => {
              setReason(e.target.value);
            }}
          />
        </>
      )}
      <p>忘れ物がないようにお気をつけください。</p>
      <div>
        <SingleCheckBox
          label={"20L以上の給油や洗車をした"}
          value={check4}
          onChange={() => {
            setCheck4(!check4);
          }}
        />
      </div>
      <CommonButton disabled={!check4} onClick={postBadNews}>
        返却時の外観チェックへ
      </CommonButton>
    </>
  );
};

import styled from "styled-components";

export const SingleCheckBox = ({
  label,
  value,
  onChange=()=>{},
  isLeft = false,
}: {
  label?: string;
  value?: boolean;
  onChange?: () => void;
  isLeft?: boolean;
}) => {

  if (isLeft) {
    return (
      <>
        <label>
          <CheckBoxOrg
            type="checkbox"
            // value={value}
            // checked={value}
            onChange={() => {
              onChange!();
            }}
          />
          <span>{label}</span>
        </label>
      </>
    );
  }

  return (
    <>
      <label>
        <span>{label}</span>
        <CheckBoxOrg
            type="checkbox"
            // value={value}
            checked={value}
            onChange={(e) => {
              onChange!();
            }}
          />
      </label>
    </>
  );
};

const CheckBoxOrg = styled.input`

`;

import { useState } from "react";
import { CommonButton } from "../../utils/Button";
import styled from "styled-components";
import * as color from "../../utils/color";
import { CommonTextArea } from "../../utils/TextField";
import { RadioGroup } from "../../utils/Radio";
import { SingleCheckBox } from "../../utils/CheckBox";
import { Navigate } from "react-router-dom";


export const DisplayKeyBox = ({isPreUse,keyvoxPlace,passCode}:{isPreUse?:boolean,passCode:string,keyvoxPlace:string})=>{
    // const data ={
    //   keyPlace:"フォレスタ円山　マンション1階郵便ボックス305号郵便受け(右5右5左1で開きます)内にクルマ鍵を収納したKeyvoxがあります",
    //   keyBoxPassword:1234,
    // }
    const [isCheck,setIscheck] = useState(false)
    const [isDirtyOrLostItem,setIsDirtyOrLostItem] = useState("0")
    const [report,setReport]=useState("")
    const [finish,setFinish]=useState(false)
    const executeReport = ()=>{
      // 忘れ物報告とかのAPIを叩く。
      setFinish(true)
    }

    if(finish){
      return <Navigate replace to="/"/>
    }
  
      return <>
      <div>キーボックスの場所</div>
      <ImportantCard>{keyvoxPlace}</ImportantCard>
      <div>車の鍵を収納するキーボックス解錠の暗証番号</div>
      <ImportantCard>{passCode}</ImportantCard>
      <p>※１時間以内に解錠しない場合無効になります。<br/>忘れ物に注意してください。</p>
      <p>法令上の日常点検</p>
      <SingleCheckBox label={"した"} value={isCheck} onChange={()=>{setIscheck(!isCheck)}}/>
      
      <p>室内に忘れ物または汚れがある</p>
      <RadioGroup radioProps={[{label:"はい",value:"1"},{label:"いいえ",value:"0"}]} onChange={(e)=>{setIsDirtyOrLostItem(e.target.value)}} defaultValue={isDirtyOrLostItem}/>
      
      {Boolean(Number(isDirtyOrLostItem)) ? <>
      <p>内容について記述</p>
      <CommonTextArea onChange={(e)=>{setReport(e.target.value)}}/>
      </>: <><br/></>
      }
      <CommonButton onClick={executeReport} disabled={!isCheck}>報告する</CommonButton>
      </>
  }
  
  const ImportantCard = styled.div`
  width:100%;
  text-align:center ;
  overflow-wrap:break-word;
  /* overflow: auto; */
  background-color:${color.Gray1} ;
  font-size:18px;
  padding:10px;
  /* display:block ; */
  `
  

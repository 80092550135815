import {
  CommonButton as _CommonButton,
  AgreeButton as _AgreeButton,
} from "../../utils/Button";
import { Container } from "../../utils/common";
import { CommonSelect } from "../../utils/Select";
import styled from "styled-components";
import { useState, useReducer, useEffect, useMemo } from "react";
import { CannnotReturnForAccident } from "./CannnotReturnForAccident";
import { CannnotReturnForPenalty } from "./CannnotReturnForPenalty";
import { ExtensionReservation } from "./ExtensionReservation";
import { NomalReturn } from "./NomalReturn";
import { CheckCarBodys } from "../../components/CheckCarBodys";
import * as color from "../../utils/color";
import { InputCarDamage } from "../../components/InputCarDamage";
import { DisplayKeyBox } from "../UseCar/DisplayKeyBox";
import { initialUseCarState, UseCarReducer } from "../../reducer/useCarReducer";
import { Navigate, useParams } from "react-router-dom";
import { api, ReqAndRes } from "../../api";

export const NonNormalReturn = () => {

  const [passCode, setPasscode] = useState("");

  const [mode, setMode] = useState(1);
  const [useCarState, useCarDispatch] = useReducer(
    UseCarReducer,
    initialUseCarState
  );
  // 免許証
  // 画像x8
  // 傷あるかどうか、
  // 車を選んだ段階で。
  let { reservationId } = useParams();
  const [reservation, setReservation] =
    useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]>();
  // const [car, setCar] = useState<ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"]>();
  

  useEffect(() => {
    (async () => {
      api("RETRIEVE usages/reservations/", { id: Number(reservationId) })
        .then((res) => {
          // 予約が取得できている状況。
          setReservation(res.data);
        })
        .catch((err) => {
          // エラーページにとばしたほうがいいかも。。
        });
    })();
    // TODO 穴ではある。どこかで書き直したいところ。
    (async () => {
      await api("GET usages/reservations/get_keyvox_pass_code/", {
        id: Number(reservationId!),
      })
        .then((res) => {
          console.log(res);
          setPasscode(res.data.passCode);
        })
        .catch((err) => {
          console.log(err);
        });
    })();
  }, [reservationId]);

  if (mode === 1) {
    // 最初の画面
    return (
      <Container>
        <AgreeButton
          onClick={(e) => {
            setMode(2);
          }}
        >
          事故で帰れない
        </AgreeButton>
        <AgreeButton
          onClick={(e) => {
            setMode(3);
          }}
        >
          違反で帰れない
        </AgreeButton>
        <AgreeButton
          onClick={(e) => {
            setMode(4);
          }}
        >
          車の故障や鍵の紛失・盗難などの上記以外の理由で返却ができない
        </AgreeButton>
      </Container>
    );
  }

  if (mode === 2) {
    return (
      <Container>
        <CannnotReturnForAccident car={reservation?.car!} reservationId={Number(reservationId)}/>
      </Container>
    );
  }

  if (mode === 3) {
    return (
      <Container>
        <CannnotReturnForPenalty car={reservation?.car!} reservationId={Number(reservationId)}/>
      </Container>
    );
  }

  if (mode === 4) {
    return (
      <Container>
        車の故障や鍵の紛失・盗難などの上記以外の理由で返却ができない
      </Container>
    );
  }

  return <></>;
};

const AgreeButton = styled(_AgreeButton)`
  margin-bottom: 10px; ;
`;

export type inputStringType = {
  value: string;
  validation: string[];
};

export type inputNumberType = {
  value: number|undefined;
  validation: string[];
};

export type inputBooleanType = {
  value: boolean;
  validation: string[];
};

export const initailStringInput = {
  value: "",
  validation: [],
};

export const initailNumberInput = {
  value: undefined,
  validation: [],
};

export const initailBooleanInput = {
  value: false,
  validation: [],
};



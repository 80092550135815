import { CommonButton as _CommonButton } from "../../utils/Button";
import styled from "styled-components";
import { useState } from "react";
import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Navigate } from "react-router-dom";
import { ReqAndRes,api } from "../../api";
import dayjs from "dayjs";


export const CannnotReturnForAccident = ({car,reservationId}:{car:ReqAndRes["RETRIEVE usages/reservations/"]["res"]["data"]["car"],reservationId:number}) => {
  const [isFinish,setIsfinish] = useState(false);
  const [isError,setIsError] = useState(false);
  const executefinish = () => {
    console.log(dayjs().format("YYYY-MM-DD HH:mm"));
    (async ()=>{
      await api("POST usages/bad-news/",{
        type:"accident",
        reservation:reservationId,
        accidentAt: dayjs().format("YYYY-MM-DDTHH:mm")
      }).then
      ((res)=>{
        console.log("完了")
        setIsfinish(true)
          // setData(res.data.results)
      }).catch(
        (err)=>{
          setIsError(true)
        }
      )
  })()
    // ここに
    // setIsContactAssuranceCompany(true);
  };


  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    {
      label: "警察に電話してください",
      description: (
        <>
          警察に連絡した後に次のボタンを押してください。
          <br />
          必ず最後まで進み完了ボタンを押してください
        </>
      ),
    },
    {
      label: "保険情報を確認し、保険会社に連絡してください",
      description: (
        <>
          <div>契約保険会社</div>
          <div>{car.insuranceCompanyName}</div>
          <hr />
          <div>保険証保険番号</div>
          <div>{car.insuranceLicenseNo}</div>
          <hr />
          <div>車体番号</div>
          <div>{car.carNo}</div>
          <div>
            <a href={`tel:${car.insuranceCompanyTel}`}>
              <CommonButton>タップして、保険会社へ電話</CommonButton>
            </a>
          </div>
        </>
      ),
    },
    {
      label: "オーナに電話してください、",
      description: (
        <>
          <a href={`tel${car.ownerTel}`}>
            <CommonButton
              onClick={() => {
                // 車両の貸し出しステータスを負荷に変える。
                // setIsContactAssuranceCompany(true);
              }}
            >
              オーナーに電話
            </CommonButton>
          </a>
        </>
      ),
    },
    {
      label: "完了ボタンを押してください",
      description: (
        <>
          <CommonButton onClick={executefinish}>完了</CommonButton>
        </>
      ),
    },
  ];

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  if(isFinish) {
    return <Navigate replace to="/"/>
  }

  if(isError) {
    <>エラーが発生しています。もう一度時間をおいて試してください。
    それでも無理な場合は管理者に問い合わせてください。</>
  }

  return (
    <>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel
              optional={
                index === 2 ? (
                  <Typography variant="caption">Last step</Typography>
                ) : null
              }
            >
              {step.label}
            </StepLabel>
            <StepContent>
              {step.description}
              <Box sx={{ mb: 2 }}>
                <div>
                  {!(index === steps.length - 1) && (
                    <>
                      <Button
                        variant="contained"
                        onClick={handleNext}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        {index === steps.length - 1 ? "完了" : "次へ"}
                      </Button>
                      <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                      >
                        Back
                      </Button>
                    </>
                  )}
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </>
  );
};

const CommonButton = styled(_CommonButton)`
  margin-bottom: 10px; ;
`;
